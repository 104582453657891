import styled from "styled-components";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .webinar-list {
    display: block;
    margin: 0 auto;
    max-width: 900px;
    .web-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .webinar-cards--active {
      box-shadow: 0 1px 14px 3px var(--gray-outline-color);
    }
    .webinar-cards {
      .card-content {
        padding: 20px;
        position: relative;
        @media (max-width: 767px) {
          padding: 16px;
        }
        .item {
          display: flex;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          .img-wrapper {
            width: 100%;
            max-width: 252px;
            min-width: 252px;
            border-radius: 10px;
            background-color: var(--icon_background);
            overflow: hidden;
            position: relative;
            min-height: 128px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 100%;
              max-height: 128px;
              vertical-align: middle;
            }
            @media (max-width: 767px) {
              max-width: 100%;
              margin-bottom: 16px;
            }
            .webinarCategory {
              border: 2px solid var(--icon_background);
              border-radius: 100px;
              background-color: #ffffff;
              padding: 6px 16px 1px;
              width: auto;
              display: flex;
              align-items: center;
              min-height: 29px;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              position: absolute;
              top: 12px;
              left: 12px;
              @media (max-width: 767px) {
                top: 8px;
                left: 8px;
              }
            }
          }
          .webinar-description {
            width: 100%;
            padding-left: 20px;
            @media (max-width: 767px) {
              padding-left: 0;
              width: calc(100% - 260px);
            }
            @media (max-width: 600px) {
              padding-left: 0;
              width: 100%;
            }
            .tag {
              margin: 0 0 4px 0;
              span {
                margin: 0;
                border-radius: 12.5px;
                background-color: var(--icon_background);
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 15px;
                line-height: 21px;
                text-align: center;
                padding: 4px 8px 0;
                min-width: 69px;
              }
            }
            .description {
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              color: var(--gray-text-color);
              margin-top: 4px;
              .custom-rss-icon {
                position: absolute;
                width: 15px;
                left: 4px;
                top: 4px;
              }
              .nlink-secondary {
                font-size: 15px;
                margin-left: 5px;
                line-height: 17px;
                padding-top: 0px;
                border-width: 1px !important;
              }
              .read-more-link {
                margin: 5px 0px;
                display: block;
                width: fit-content;
              }
            }
          }
          .webinar-schedule-info {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 2px solid var(--gray-outline-color);
            min-width: 235px;
            @media (max-width: 600px) {
              margin-left: 0px;
              padding-left: 0px;
              margin-top: 16px;
              padding-top: 16px;
              border-left: 0;
              border-top: 2px solid var(--gray-outline-color);
              width: 100%;
              text-align: center;
            }
            .sub-header-primary {
              color: var(--primary-dark-color);
              font-family: "TTCommons-DemiBold";
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 19px;
            }
            .text-grey {
              display: flex;
              align-items: center;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              color: var(--primary-dark-color);
              margin: 4px 0;
              @media (max-width: 600px) {
                justify-content: center;
              }
              span {
                display: flex;
                align-items: center;
                background-color: var(--gray-outline-color);
                width: 30px;
                height: 30px;
                border-radius: 100px;
                justify-content: center;
                margin-right: 8px;
                margin-top: -2px;
                svg {
                  color: #fff;
                  width: 20px;
                  height: 20px;
                }
                img {
                  vertical-align: middle;
                }
              }
            }
            .text-secondary {
              color: var(--primary-dark-color) !important;
              margin: 4px 0;
              display: block;
              .download-btn {
                margin: 10px 0 13px;
                span {
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Regular";
                  font-size: 15px;
                  font-weight: 400;
                  letter-spacing: 0;
                  line-height: 17px;
                  text-align: center;
                  border-bottom: 2px solid var(--primary-dark-color);
                }
              }
            }
            .btn {
              margin-top: 4px;
              button {
                width: auto;
                min-width: 160px;
              }
            }
          }
        }
      }
    }
  }
`;
