import React from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import OPTGuide from "../../../../components/OPTCalculator/OPTGuide/OPTGuide";
import OPTFooter from "../../../../components/OPTCalculator/OPTFooter/OPTFooter";
import OPTReview from "../../../../components/OPTCalculator/OPTReview/OPTReview";
import FullTimeCPTUsed from "./FullTimeCPTUsed/FullTimeCPTUsed";
import AvailablePostCompletionOPT from "./AvailablePostCompletionOPT/AvailablePostCompletionOPT";
import DeadlinesOnPostCompletionOPT from "./DeadlinesOnPostCompletionOPT/DeadlinesOnPostCompletionOPT";
import DSOUSCIS from "./DSOUSCIS/DSOUSCIS";
import PreferredOPTStartDate from "./PreferredOPTStartDate/PreferredOPTStartDate";
import FormI765Deadline from "./FormI765Deadline/FormI765Deadline";
import InfoIconTooltip from "../../../../components/InfoIconTooltip/InfoIconTooltip";

import OPTCalculatorMiddleStyles from "./OPTCalculatorMiddleStyles";

const OPTCalculatorMiddle = () => {
  const {
    optStatusList = [],
    optIndex = null, // optIndex has null as default value
  } = useSelector((store) => store.optStore);

  return (
    <OPTCalculatorMiddleStyles>
      {optIndex === null ? (
        <OPTGuide />
      ) : optIndex >= 0 ? (
        <>
          {optStatusList[optIndex]?.fullTitle ? (
            <h2 className="opt_full-title">
              {optIndex === 5 ? (
                <>
                  Determine Form I-765 filing deadline based on the date the DSO
                  recommends OPT in SEVIS
                  <InfoIconTooltip
                    variant="header"
                    aria-label="note"
                    placement="bottom"
                    margin="-1px 0px 0px 8px"
                    content={
                      <Typography>
                        <>
                          Form I-765, also known as the “Application for
                          Employment Authorization” is used to determine a
                          foreign worker’s eligibility for a work permit in the
                          US.{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              "https://interstride.com/blog/what-is-form-i-765/#:~:text=byInterstride,provesuchtopotentialemployers"
                            }
                          >
                            Read more about Form I-765
                          </a>
                        </>
                      </Typography>
                    }
                  />
                </>
              ) : (
                optStatusList[optIndex]?.fullTitle
              )}
            </h2>
          ) : (
            ""
          )}
          {optStatusList[optIndex]?.subTitle ? (
            <h2 className="opt_sub-title">
              {optStatusList[optIndex]?.subTitle}
            </h2>
          ) : (
            ""
          )}
          {optIndex === 0 ? (
            <FullTimeCPTUsed />
          ) : optIndex === 1 ? (
            <AvailablePostCompletionOPT />
          ) : optIndex === 2 ? (
            <DeadlinesOnPostCompletionOPT />
          ) : optIndex === 3 ? (
            <DSOUSCIS />
          ) : optIndex === 4 ? (
            <PreferredOPTStartDate />
          ) : optIndex === 5 ? (
            <FormI765Deadline />
          ) : optIndex === 6 ? (
            <OPTReview />
          ) : (
            ""
          )}
        </>
      ) : (
        <></>
      )}
      {optIndex !== null && optIndex >= 0 && optIndex < 6 ? <OPTFooter /> : ""}
    </OPTCalculatorMiddleStyles>
  );
};

export default OPTCalculatorMiddle;
