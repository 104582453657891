import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt-guide {
    &-hero-banner {
      display: flex;
      border-radius: 10px;
      background-color: var(--primary-main-color);
      padding: 35px 15px;
      align-items: center;
      @media (max-width: 767px) {
        padding: 24px 15px;
      }
      @media (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      &__logo-container {
        width: 100%;
        max-width: 152px;
        max-height: 152px;
        margin-right: 28px;
        @media (max-width: 767px) {
          max-width: 120px;
          max-height: 120px;
          margin-right: 16px;
        }
        @media (max-width: 480px) {
          margin-bottom: 15px;
          margin-right: 0px;
        }
        img {
          width: 100%;
        }
      }
      &__summary {
        width: 100%;
        h2 {
          color: #ffffff;
          font-family: "TTCommons-DemiBold";
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          margin: 0 0 5px 0;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 19px;
          }
        }
        p {
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 0 15px 0;
          font-weight: 400;
          @media (max-width: 767px) {
            font-size: 15px;
            line-height: 17px;
          }
        }
        button {
          max-width: 205px;
          @media (max-width: 480px) {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .opt-guide-description {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    padding: 0 16px 0 32px;
    margin: 40px 0;
    @media (max-width: 767px) {
      margin: 24px 0;
      padding: 0 8px 0 8px;
    }
    p {
      margin: 0;
    }
    h3 {
      margin: 18px 0 12px 0;
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      color: var(--primary-dark-color);
      display: flex;
    }
    ul {
      margin: 0;
      padding-left: 0;
      line-height: 20px;
      font-family: "TTCommons-Regular";
      list-style: none;
      li {
        position: relative;
        padding-left: 15px;
        &:before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 100px;
          background: var(--primary-dark-color);
          left: 2px;
          top: 6px;
        }
        + li {
          margin-top: 4px;
        }
      }
    }
  }
`;
