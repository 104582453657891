import React from "react";
import styles from "./Experience.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import { ProfessionalExperience } from "./ProfessionalExperience/ProfessionalExperience";
import LeadershipExperience from "./LeadershipExperience/LeadershipExperience";
import { ExperienceLanguages } from "./Languages/Language";
import { ProfileExperience } from ".";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

const cx = createModuleStyleExtractor(styles);
export const Experience = () => {
  return (
    <ProfileExperience>
      {({ experiences }) => (
        <>
          <h1 className={cx(["o-experience-heading"])}>Experience</h1>
          <div className={cx(["o-experience-container"])}>
            <h1 className={cx("o-experience-container__top-heading")}>
              Professional experience
              <InfoIconTooltip
                margin="0"
                placement="bottom"
                aria-label="Professional experience"
                content={
                  "This could be a job, internship, or freelance work that contributes directly to your career interests."
                }
              />
            </h1>
            <CoverHeader>
              <ProfessionalExperience
                experiences={experiences?.experiences || []}
              />
            </CoverHeader>
          </div>

          <div className={cx(["o-experience-container"])}>
            <h1 className={cx("o-experience-containers__top-heading")}>
              Extracurricular & leadership experience
              <InfoIconTooltip
                margin="0"
                placement="bottom"
                aria-label="Extracurricular & leadership experience"
                content={
                  "Think about your experience outside of the classroom and workplace that highlight your interests and abilities. Maybe you tutor students, lead a student organization, or organize events – think about experiences that demonstrate valuable and transferrable skills!"
                }
              />
            </h1>
            <CoverHeader>
              <LeadershipExperience
                experiences={experiences?.extra_experiences || []}
              />
            </CoverHeader>
          </div>

          <div className={cx(["o-laguage-container"])}>
            <h1 className={cx("o-laguage-container__top-heading")}>
              Languages
            </h1>
            <CoverHeader>
              <ExperienceLanguages languages={experiences?.languages || []} />
            </CoverHeader>
          </div>
        </>
      )}
    </ProfileExperience>
  );
};
