import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import TextboxCustom from "../../../../../components/Textbox/TextboxCustom";
import InfoIconTooltip from "../../../../../components/InfoIconTooltip/InfoIconTooltip";

import DSOUSCISStyles from "./DSOUSCISStyles";

import {
  disableNext,
  updateDSOUSCIS,
} from "../../../../../redux/reducers/optReducer";

import arrowRightIcon from "../../../../../assets/svg/arrow-right.svg";

const USCISurl = "https://egov.uscis.gov/processing-times/";

const DSOUSCIS = () => {
  const dispatch = useDispatch();
  const { optCalculatorState = {} } = useSelector((store) => store.optStore);
  const { dso_uscis_processing_times = {} } = optCalculatorState;
  const { value = {} } = dso_uscis_processing_times;
  const { dso = null, uscis = {} } = value; // null value is intended to keep the input clear and show placeholder
  const { months = null, weeks = null, days = null } = uscis; // here null default values are needed to keep the input from showing 0 as default

  useEffect(() => {
    // Any one of month, week or day is mandatory
    if (!dso || (!months && !weeks && !days)) {
      dispatch(disableNext(true));
    } else {
      dispatch(disableNext(false));
    }
  }, [dso, months, weeks, days]);

  const handleDSO = (e, min, max) => {
    dispatch(
      updateDSOUSCIS({
        value: {
          uscis,
          dso:
            e.target.value > max
              ? max
              : e.target.value < min
              ? min
              : e.target.value,
        },
      })
    );
  };

  const handleUSCIS = (e, type, min, max) =>
    dispatch(
      updateDSOUSCIS({
        value: {
          dso,
          uscis: {
            ...uscis,
            [type]:
              e.target.value > max
                ? max
                : e.target.value < min
                ? min
                : e.target.value,
          },
        },
      })
    );

  return (
    <DSOUSCISStyles>
      <OPTQueryContainer
        title={
          <>
            Enter the number of calendar days it takes a DSO at your school to
            process an OPT request.
            <InfoIconTooltip
              variant="header"
              aria-label="note"
              placement="bottom"
              margin="-1px 0px 0px 8px"
              content={
                <Typography>
                  Check with your ISSS office to get a specific timeline. To get
                  a rough estimate, you can also factor a two-week average
                  processing time.
                </Typography>
              }
            />
          </>
        }
      >
        <TextboxCustom
          name="opt_dso_days"
          type="number"
          aria-labelledby="enter days"
          placeholder="Enter days"
          value={dso}
          onChange={(e) => handleDSO(e, 0, 365)}
          min={"0"}
          maxLength={3}
          max={365}
        />
      </OPTQueryContainer>
      <OPTQueryContainer
        title={
          "Look up the Form I-765 processing times for F-1 employment at the USCIS Service Center where the  OPT application will be filed."
        }
      >
        <div className="view-btn">
          <a href={USCISurl} target="_blank" rel="noopener noreferrer">
            View USCIS processing times{" "}
            <img src={arrowRightIcon} alt="btn-arrow" />
          </a>
        </div>
      </OPTQueryContainer>
      <OPTQueryContainer
        title={"Enter the processing time in the appropriate column below."}
      >
        <div className="processing_input__container">
          <div className="processing_input__header">
            <label>Month</label>
            <label>Weeks</label>
            <label>Days</label>
          </div>
          <div className="processing_input__value-container">
            <TextboxCustom
              name="opt_uscis_months"
              type="number"
              aria-labelledby="Enter value"
              placeholder="Enter value"
              value={months}
              onChange={(e) => handleUSCIS(e, "months", 0, 12)}
              min={"0"}
              maxLength={2}
              max={12}
            />
            <TextboxCustom
              name="opt_uscis_weeks"
              type="number"
              aria-labelledby="Enter value"
              placeholder="Enter value"
              value={weeks}
              onChange={(e) => handleUSCIS(e, "weeks", 0, 52)}
              min={"0"}
              maxLength={2}
              max={52}
            />
            <TextboxCustom
              name="opt_uscis_days"
              type="number"
              aria-labelledby="Enter value"
              placeholder="Enter value"
              value={days}
              onChange={(e) => handleUSCIS(e, "days", 0, 365)}
              min={"0"}
              maxLength={3}
              max={365}
            />
          </div>
        </div>
      </OPTQueryContainer>
    </DSOUSCISStyles>
  );
};

export default DSOUSCIS;
