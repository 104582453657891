import React from "react";
import { Tooltip } from "@mui/material";

import styles from "./InfoIconTooltip.module.scss";
import { createModuleStyleExtractor } from "../../utils/css";

import InfoIcon from "../../assets/svg/InfoIcon";

const cx = createModuleStyleExtractor(styles);

const InfoIconTooltip = ({
  content = "",
  variant = "text",
  placement = "bottom-end",
  margin = "",
  className = null,
  ...props
}) => {
  return (
    <Tooltip
      title={<div className="info-icon-tooltip">{content}</div>}
      placement={placement}
    >
      <a
        href="/#"
        onClick={(e) => e.preventDefault()}
        className={
          className ??
          cx(["a-info-icon-tooltip", `a-info-icon-tooltip--${variant}`])
        }
        style={{ margin }}
        {...props}
      >
        <InfoIcon variant={variant} />
      </a>
    </Tooltip>
  );
};

export default InfoIconTooltip;
