import styled from "styled-components";
import { PRIMARY_COLOR } from "../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .career-widget {
    max-width: 906px;
    margin: 0 auto;
    @media (max-width: 906px) {
      padding: 24px 16px;
    }
    .title-primary {
      color: var(--primary-dark-color);
      font-size: 42px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 38px;
      font-family: "TTCommons-DemiBold";
      padding: 0;
      margin: 0 0 16px 0;
      @media (max-width: 906px) {
        font-size: 24px;
        line-height: 24px;
        margin: 0 0 8px 0;
      }
    }
    .tabs-list {
      display: flex;
      justify-content: center;
      .MuiTabs-root {
        .MuiTabs-flexContainer {
          overflow-x: auto;
        }
        button {
          border-bottom: 3px solid #d1dde5;
          padding: 18px 18px;
          box-shadow: none;
          border-radius: 0;
          min-width: 150px;
          opacity: 1;
          @media (max-width: 906px) {
            padding: 12px 12px;
            min-width: 124px;
          }
          @media (max-width: 480px) {
            min-width: auto;
            padding: 12px 16px;
          }
          &:focus {
            box-shadow: none !important;
          }
          .MuiTouchRipple-ripple * {
            background-color: var(--widget-theme-color) !important;
          }
          .tabs-list-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
              max-width: 38px;
              max-height: 38px;
              margin: 0 0 6px 0;
              @media (max-width: 906px) {
                max-width: 32px;
                max-height: 32px;
                margin: 0 0 4px 0;
              }
            }
            span {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-weight: 400;
              font-size: 20px;
              letter-spacing: 0;
              line-height: 23px;
              text-align: center;
              text-transform: capitalize;
              @media (max-width: 906px) {
                font-size: 15px;
                line-height: 17px;
              }
            }
            &.tabs-list-header-active {
              span {
                color: var(--widget-theme-color);
              }
              svg {
                #widget-category-icon {
                  stroke: var(--widget-theme-color);
                }
              }
            }
          }
        }
        .MuiTabs-indicator {
          height: 3px;
          background-color: var(--widget-theme-color);
        }
      }
    }
    div[role="tabpanel"] {
      padding: 30px 0;
      @media (max-width: 991px) {
        padding: 24px 0;
      }
    }
    .widget-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      margin-top: 20px;
      h3 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        margin: 0 10px 0 0;
        padding: 4px 0 0 0;
      }
      img {
        max-height: 22px;
      }
    }
    .career-widget-invalid-ui {
      .career-widget-invalid-ui-content {
        text-align: center;
        max-width: 480px;
        margin: 0 auto;
        padding-bottom: 114px;
        .logo {
          img {
            max-height: 100px;
            max-width: 234px;
            vertical-align: middle;
            width: 100%;
            @media (max-width: 767px) {
              max-width: 160px;
            }
          }
        }
        h2 {
          color: var(--primary-dark-color);
          ont-family: "TTCommons-DemiBold";
          font-size: 42px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 40px;
          margin: 100px 0 16px;
          text-align: center;
          @media (max-width: 767px) {
            font-size: 30px;
            line-height: 34px;
            margin: 50px 0 16px;
          }
        }
        h6 {
          color: var(--gray-text-color);
          font-family: TT Commons;
          font-family: TTCommons-Regular;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 22px;
          margin: 80px auto 0;
          max-width: 500px;
          text-align: center;
          @media (max-width: 767px) {
            margin: 50px auto 0;
          }
          a {
            border-bottom: 1px solid ${PRIMARY_COLOR};
            color: ${PRIMARY_COLOR};
            text-decoration: none;
          }
        }
      }
    }
    .career-widget-loader {
      position: fixed;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      .career-widget-loader-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          max-height: 100px;
          max-width: 234px;
          vertical-align: middle;
          width: 100%;
          min-width: 234px;
          margin: 0 0 0px 0;
        }
      }
    }

    .collapsible-view {
      overflow: visible;
    }
  }
`;
