import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const fetchUserAmbassadorProfile = async () => {
  const response = await axiosInstance.get(
    `/users/ambassador_detail`,
    headers_with_token()
  );
  return response?.data;
};

export const updateUserAmbassadorProfile = async (user_id, data) => {
  const response = await axiosInstance.patch(
    `/users/${user_id}/update_ambassador_details`,
    data,
    headers_with_token({ formData: true })
  );
  return response.data;
};

export const createUserAmbassadorProfile = async (data) => {
  const response = await axiosInstance.post(
    `/users/ambassador_details`,
    data,
    headers_with_token({ formData: true })
  );
  return response.data;
};
