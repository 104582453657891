import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { isEmpty } from "lodash";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import OPTGuideStyles from "./OPTGuideStyles";
import {
  resetOPTInitialState,
  updateOPTIndex,
} from "../../../redux/reducers/optReducer";

import CalculatorIcon from "../../../assets/svg/CalculatorColored.svg";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

const OPTGuide = () => {
  const dispatch = useDispatch();
  const optLocalStorageItem = JSON.parse(
    localStorage.getItem("interstride_opt")
  );

  useEffect(() => {
    if (!optLocalStorageItem || isEmpty(optLocalStorageItem)) {
      dispatch(resetOPTInitialState());
    }
  }, []);

  return (
    <OPTGuideStyles>
      <div className="opt-guide-hero-banner">
        <div className="opt-guide-hero-banner__logo-container">
          <img src={CalculatorIcon} alt="calculator" />
        </div>
        <div className="opt-guide-hero-banner__summary">
          <h2>
            Post-Completion Optional Practical Training (OPT) Planning Tool{" "}
          </h2>
          <p>Calculate your OPT eligibility and important deadlines</p>
          <ButtonCustom
            width={248}
            className="focus-white"
            onClick={() => dispatch(updateOPTIndex(0))}
          >
            Get started
          </ButtonCustom>
        </div>
      </div>
      <div className="opt-guide-description">
        <h3>
          What is Post-Completion OPT?
          <InfoIconTooltip
            variant="header"
            aria-label="OPT note"
            placement="bottom"
            margin="0px 0px 0px 8px"
            content={
              <Typography>
                Read more about OPT here:{" "}
                <a
                  href="https://interstride.com/blog/optional-practical-training-opt-for-f-1-students/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="popover-link"
                >
                  Optional practical training (OPT) for F-1 students
                </a>
              </Typography>
            }
          />
        </h3>
        <p>
          OPT is a 12-month work authorization program that permits
          international students to gain professional experience without having
          to apply for another visa. Post-completion OPT allows you to work in
          the U.S. after graduation in a role that is directly related to your
          area of study.
        </p>
      </div>
    </OPTGuideStyles>
  );
};

export default OPTGuide;
