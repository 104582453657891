import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import RightSidebarCard from "../../../pages/Tools/OPTCalculator/OPTCalculatorRightSidebar/RightSidebarCard/RightSidebarCard";
import OPTReviewStyles from "./OPTReviewStyles";
import { OPT_CALCULATOR_STATUS_LIST } from "../../../constant/mockdata";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

const OPTReview = () => {
  const {
    optCalculatorState = {},
    optRightSidebarStatus = [],
    optIndex = null,
  } = useSelector((store) => store.optStore);

  if (
    (optIndex === 0 && !optCalculatorState?.full_time_cpt_used?.isAuthorized) ||
    (optIndex === 1 &&
      !optCalculatorState?.available_post_completion_opt?.isAuthorized)
  ) {
    return <></>;
  }

  if (optIndex === 0 && !optRightSidebarStatus?.[0]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 1 &&
    !optRightSidebarStatus?.[1]?.[1]?.value &&
    !optRightSidebarStatus?.[1]?.[2]?.value
  ) {
    return <></>;
  }

  if (
    optIndex === 2 &&
    (!optRightSidebarStatus?.[2]?.[0]?.value ||
      optRightSidebarStatus?.[2]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[1]?.value ||
      optRightSidebarStatus?.[2]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[2]?.value ||
      optRightSidebarStatus?.[2]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[3]?.value ||
      optRightSidebarStatus?.[2]?.[3]?.value ===
        "Invalid date and Invalid date")
  ) {
    return <></>;
  }

  if (optIndex === 3 && !optRightSidebarStatus?.[3]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 4 &&
    (!optRightSidebarStatus?.[4]?.[0]?.value ||
      optRightSidebarStatus?.[4]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[1]?.value ||
      optRightSidebarStatus?.[4]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[2]?.value ||
      optRightSidebarStatus?.[4]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[3]?.value ||
      optRightSidebarStatus?.[4]?.[3]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[4]?.value ||
      optRightSidebarStatus?.[4]?.[4]?.value === "Invalid date")
  ) {
    return <></>;
  }

  if (
    optIndex === 5 &&
    (!optRightSidebarStatus?.[5]?.[0]?.value ||
      optRightSidebarStatus?.[5]?.[0]?.value === "Invalid date")
  ) {
    return <></>;
  }

  return (
    <OPTReviewStyles>
      {optRightSidebarStatus?.map((d, idx) => {
        return (
          <>
            <div className="opt-review-title" key={idx}>
              {idx === 5 ? (
                <>
                  Determine Form I-765 filing deadline based on the date the DSO
                  recommends OPT in SEVIS
                  <InfoIconTooltip
                    variant="header"
                    aria-label="note"
                    placement="bottom"
                    margin="-1px 0px 0px 8px"
                    content={
                      <Typography>
                        <>
                          Form I-765, also known as the “Application for
                          Employment Authorization” is used to determine a
                          foreign worker’s eligibility for a work permit in the
                          US.{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              "https://interstride.com/blog/what-is-form-i-765/#:~:text=byInterstride,provesuchtopotentialemployers"
                            }
                          >
                            Read more about Form I-765
                          </a>
                        </>
                      </Typography>
                    }
                  />
                </>
              ) : (
                <>{OPT_CALCULATOR_STATUS_LIST[idx]?.fullTitle}</>
              )}
            </div>
            <div className="opt-review-card" key={idx + "card"}>
              {d.map((data, idx) => (
                <RightSidebarCard data={data} key={idx} />
              ))}
            </div>
          </>
        );
      })}
      <p className="estimate-condition">
        *Assumes an additional 10-day mailing period.
      </p>
    </OPTReviewStyles>
  );
};
export default OPTReview;
