import React from "react";
import styles from "./Career.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CoverHeader } from "../../atoms/CoverHeader/CoverHeader";
import { CareerIntro } from "./CareerIntro/CareerIntro";
import { CareerVisa } from "./CareerVisa/CareerVisa";
import { CareerInformation } from "./CareerInformation/CareerInformaion";
import { ProfileCareer } from "./index";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

const cx = createModuleStyleExtractor(styles);

export const Career = () => {
  return (
    <ProfileCareer>
      {({ career, student_view_preference = "international" }) => (
        <>
          <div className={cx(["o-career-container"])}>
            <h1 className={cx("o-career-container__top-heading")}>Career</h1>
            <CoverHeader>
              <CareerIntro data={career} />
            </CoverHeader>
          </div>

          <div className={cx(["o-career-information"])}>
            <h1 className={cx("o-career-information__top-heading")}>
              Career information
            </h1>
            <CoverHeader>
              <CareerInformation data={career} />
            </CoverHeader>
          </div>

          {student_view_preference === "international" && (
            <div className={cx(["o-career-visa-status"])}>
              <h1 className={cx("o-career-visa-status__top-heading")}>
                Visa Status
                <InfoIconTooltip
                  margin="0"
                  aria-label="Visa Status"
                  content={
                    "Knowing your immigration status helps us connect you to companies that actively hire international candidates in their hiring process. We will never share this data with third parties without your consent."
                  }
                />
              </h1>
              <CoverHeader>
                <CareerVisa data={career} />
              </CoverHeader>
            </div>
          )}
        </>
      )}
    </ProfileCareer>
  );
};
