import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .middle-section {
    .topic-post-wrapper {
      width: 100%;
      > div {
        > div {
          > div {
            padding: 8px;
          }
        }
      }
    }
    .post-card {
      padding: 20px;
      overflow: visible !important;
      .MuiCardHeader-root {
        padding: 0;
        margin: 0 0 10px 0;
        .MuiCardHeader-avatar {
          margin-right: 8px;
          .MuiAvatar-root {
            width: 50px !important;
            height: 50px !important;
          }
        }
        .MuiCardHeader-content {
          display: flex;
          align-items: center;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          .MuiCardHeader-title {
            margin: 0 10px 0 0;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 17px;
            padding: 2px 0 0 0;
            @media (max-width: 767px) {
              width: 100%;
              margin: 0;
            }
          }
          .MuiCardHeader-subheader {
            color: var(--field-label-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
          }
        }
      }
      .card-content {
        padding: 0;
        .topic-post {
          &__title {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 13px;
            &__avatar {
              .MuiAvatar-root {
                width: 50px !important;
                height: 50px !important;
              }
            }
            &__name-wrapper {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              row-gap: 6px;
            }
            &__name {
              display: flex;
              align-items: center;
              h4 {
                margin: 3px 0 0 0;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
            &__tag {
              min-width: fit-content;
              padding: 6px 10px;
              height: 25px !important;
              margin: 0 0 0 0;
              font-family: "TTCommons-Regular";
              text-align: center;
              color: #ffffff;
              background: var(--link-color);
              font-size: 15px;
              letter-spacing: 0;
              line-height: 16px;
              border-radius: 12.5px;
              &--mentor {
                color: #ffffff;
                background: #335cd4;
              }
              &--alumni,
              &--student,
              &--staff {
                color: var(--primary-dark-color);
                background: #d7ddf0;
              }
              &--community-creator,
              &--external {
                color: var(--primary-dark-color);
                background: var(--icon_background);
              }
              &--ambassador {
                color: #a80c06;
                background: rgba(247, 100, 95, 0.09);
              }
            }

            &__time {
              min-width: 72px;
              height: 16px !important;
              color: var(--field-label-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 19px;
              min-width: fit-content;
              flex-grow: 1;
            }
            &__options {
              position: relative;
              display: flex;
              gap: 10px;
              &__menu-icon {
                cursor: pointer;
              }
              &__menu {
                z-index: 40;
                position: absolute;
                right: 0px;
                top: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 200px;
                border-radius: 10px;
                background: #ffffff;
                box-shadow: var(--card-box-shadow);
                padding: 0px 15px;
                span {
                  cursor: pointer;
                  width: 100%;
                  text-align: center;
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Regular";
                  font-size: 18px;
                  letter-spacing: 0;
                  line-height: 18px;
                  padding: 20px 0px;
                  + span {
                    border-top: 2px solid #eaeef3;
                  }
                }
              }
            }
          }
          &__topic {
            margin: 0px 0 13px 0;
            height: 35px;
            width: fit-content;
            border-radius: 25px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            padding-inline: 14px;
            display: flex;
            align-items: center;
            gap: 8px;
            &__name {
              height: 15px;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
            }
          }
          .header-primary {
            &__content {
              h3 {
                margin: 0 0 10px 0;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
                font-size: 19px;
                letter-spacing: 0;
                line-height: 25px;
                white-space: break-spaces;
              }
              &__read-more-less {
                margin: 10px 0px;
                padding: 10px 12px;
                height: 35px !important;
                cursor: pointer;
                height: 35px;
                border-radius: 45px;
                background-color: #e8eef2;
                min-width: fit-content;
                height: 19px;
                width: 76px;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
              }
              &__attachment {
                margin-bottom: 10px;
                img {
                  width: 100%;
                }
              }
            }
            &__url {
              margin-bottom: 10px;
              background: var(--icon_background);
              border-radius: 10px;
              overflow: hidden;
              cursor: pointer;
              &__image {
                margin-bottom: -7px;
                img {
                  width: 100%;
                }
              }
              &__title {
                margin: 10px !important;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
                font-size: 20px;
                letter-spacing: 0;
                line-height: 25px;
                white-space: break-spaces;
              }
              &__description {
                padding-inline: 10px;
                display: inline-block;
                color: var(--gray-color);
                font-family: "TTCommons-Regular";
                font-size: 17px;
                line-height: 19px;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
              }
            }
          }
          .image-box2 {
            margin-bottom: 10px;
            .image {
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .topic-preview-video-section {
            width: 100%;
            .topic-preview-video-section > div {
              width: 100% !important;
            }
          }
          .media {
            width: 100%;
            video {
              width: 100%;
              height: auto;
              vertical-align: middle;
            }
            .title {
              margin: 0 0 10px 0;
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 22px;
              letter-spacing: 0;
              line-height: 24px;
            }
            .image-box {
              display: flex;
              align-items: center;
              .image {
                width: 112px;
                min-width: 112px;
                height: 67px;
                overflow: hidden;
                border-radius: 10px;
                margin: 0 12px 0 0;
                img {
                  width: 100%;
                  object-fit: cover;
                  object-position: center;
                  height: 100%;
                }
              }
              .title-box {
                .title {
                  color: ${PRIMARY_COLOR};
                  margin: 0;
                }
              }
            }
          }
          .actions {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            row-gap: 8px;
            position: relative;
            &__replies,
            &__comment {
              cursor: pointer;
              display: flex;
              height: 35px;
              padding: 10px 10px 0px 10px;
              border-radius: 45px;
              background: #f5f7f9;
              img {
                width: 30px;
                height: 30px;
                margin: -8px 0 0 -4px;
              }
              span {
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
              }
            }
            &__reaction {
              cursor: pointer;
              display: flex;
              height: 35px;
              padding: 10.25px;
              border-radius: 45px;
              background: #f5f7f9;
              span {
                font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
                color: var(--primary-dark-color);
                font-size: 18px;
                letter-spacing: 0;
                line-height: 20px;
              }
            }
            &__emoji {
              position: initial;
              img {
                cursor: pointer;
              }
              &__picker {
                width: 100%;
                height: 0;
              }
            }
          }
          .comment-section {
            margin-top: 20px;
            .comment-list {
              display: flex;
              flex-direction: column;
              row-gap: 12px;
              .item {
                &__saperator {
                  border: 1px solid var(--icon_background);
                  border-radius: 10px;
                  margin-bottom: 12px;
                }
                &__title {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  margin-bottom: 6px;
                  &__avtar {
                    .MuiAvatar-root {
                      width: 40px;
                      height: 40px;
                    }
                    margin: -2px 0 0 0;
                    .initial-avatar {
                      margin: 0;
                      font-size: 20px;
                      padding-top: 4px;
                    }
                  }
                  &__name {
                    color: var(--primary-dark-color);
                    font-family: "TTCommons-Regular";
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 17px;
                  }
                  &__user-type {
                    min-width: fit-content;
                    padding: 6px 10px;
                    height: 25px !important;
                    margin: 0 0 0 0;
                    font-family: "TTCommons-Regular";
                    text-align: center;
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 16px;
                    border-radius: 12.5px;
                    color: var(--primary-dark-color);
                    background: #d7ddf0;
                    &--mentor {
                      color: #ffffff !important;
                      background: #335cd4 !important;
                    }
                  }
                  &__community-creator {
                    min-width: fit-content;
                    padding: 6px 10px;
                    height: 25px !important;
                    margin: 0 0 0 0;
                    font-family: "TTCommons-Regular";
                    text-align: center;
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 16px;
                    border-radius: 12.5px;
                    color: var(--primary-dark-color);
                    background: var(--icon_background);
                  }
                  &__time {
                    color: var(--field-label-color);
                    font-family: "TTCommons-Regular";
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 17px;
                    height: 12px;
                    min-width: fit-content;
                    flex-grow: 1;
                  }
                  &__options {
                    position: relative;
                    display: flex;
                    gap: 10px;
                    &__menu-icon {
                      cursor: pointer;
                    }
                    &__menu {
                      z-index: 40;
                      position: absolute;
                      right: 0px;
                      top: 40px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      width: 200px;
                      border-radius: 10px;
                      background: #ffffff;
                      box-shadow: var(--card-box-shadow);
                      padding: 0px 15px;
                      span {
                        cursor: pointer;
                        width: 100%;
                        text-align: center;
                        color: var(--primary-dark-color);
                        font-family: "TTCommons-Regular";
                        font-size: 18px;
                        letter-spacing: 0;
                        line-height: 18px;
                        padding: 20px 0px;
                        + span {
                          border-top: 2px solid #eaeef3;
                        }
                      }
                    }
                  }
                }
                &__comment {
                  margin: 0 0 0 50px;
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
                  font-size: 17px;
                  letter-spacing: 0;
                  line-height: 19px;
                  white-space: pre-line;
                }
              }
            }
            .comment-load-more {
              display: flex;
              justify-content: center;
              margin-top: 10px;
              span {
                width: fit-content;
                cursor: pointer;
                color: var(--primary-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 19px;
              }
            }
          }
          .comment-loader {
            display: flex;
            justify-content: center;
            margin-top: 15px;
          }
          .comment-box {
            margin-top: 15px;
            &__text {
              width: 100%;
              > div {
                margin-bottom: 0;
              }
              .form-label {
                margin: 0 0 10px 4px !important;
                color: ${PRIMARY_COLOR};
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                padding: 5px 0 0 0 !important;
              }
              .MuiInputBase-multiline {
                padding: 0;
                margin-bottom: 0;
                textarea {
                  border: 2px solid var(--gray-outline-color);
                  border-radius: 10px;
                  background-color: #ffffff;
                  padding: 21px 20px;
                  box-sizing: border-box;
                  font-size: 18px;
                  line-height: 22px;
                  font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
                  color: var(--primary-dark-color);
                  max-height: 100px;
                  overflow: auto !important;
                  &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: var(--field-label-color) !important;
                    opacity: 1;
                  }
                  &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: var(--field-label-color) !important;
                    opacity: 1;
                  }
                  &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: var(--field-label-color) !important;
                    opacity: 1;
                  }
                  &:-moz-placeholder {
                    /* Firefox 18- */
                    color: var(--field-label-color) !important;
                    opacity: 1;
                  }
                  // --Focus style
                  &:focus {
                    border-color: var(--gray-color) !important;
                  }
                  &:hover {
                    border-color: #bfced8;
                  }
                  &:focus {
                    border-color: var(--gray-color);
                  }
                  /* scroll styling start */
                  scrollbar-width: thin !important;
                  scrollbar-color: var(--light-gray-color) transparent; /* scroll thumb and track */
                  &::-webkit-scrollbar {
                    width: 7px; /* width of the entire scrollbar */
                    transition: all ease-in-out 500ms;
                  }
                  &::-webkit-scrollbar-track {
                    background: transparent !important; /* color of the tracking area */
                    border-radius: 50px;
                    width: 7px;
                    -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2)
                      0px 7px 29px 0px;
                    margin: 10px 0 10px 0;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: var(
                      --light-gray-color
                    ) !important; /* color of the scroll thumb */
                    border-radius: 50px; /* roundness of the scroll thumb */
                    height: 40px;
                  }
                  &::-webkit-scrollbar-corner {
                    border-radius: 50%;
                  }
                  &::-webkit-scrollbar-thumb:hover {
                    border: 0;
                    background-color: var(
                      --gray-color
                    ) !important; /* color of the scroll thumb */
                  }
                  /* scroll styling end */
                }
              }
            }
            &__action {
              width: 100%;
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              position: relative;
              &__left {
                display: flex;
                gap: 5px;
                .emoji-picker-wrapper {
                  height: 35px;
                  .emoji-icon {
                    cursor: pointer;
                  }
                  .emoji-picker {
                    position: absolute;
                    width: 100%;
                    left: 0px;
                    top: 40px;
                    z-index: 10;
                  }
                }
              }
              &__post {
                button {
                  height: 35px;
                  width: 78px;
                  border-radius: 45px;
                }
              }
            }
          }
        }
      }
    }
  }
  .title-withbutton {
    display: flex;
    align-items: center;
    margin: 15px 0 30px 0 !important;
    min-height: 29px;
    .title-primary {
      margin: 0 0 0 0;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      width: auto;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      font-size: 28px;
      font-weight: 500;
      display: inline-block;
    }
    .pending-request {
      position: relative;
      margin-left: 5px;
      button {
        height: auto;
        border-radius: 45px;
        width: auto;
        padding: 4px 10px 2px 10px;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        min-width: 164px;
      }

      .card-wrapper {
        position: absolute;
        z-index: 10;
        background: #fff;
        top: 40px;
        right: 0px;
        .request-box {
          overflow: visible;
          border-radius: 10px;
          background-color: #ffffff;
          box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
          position: relative;
          padding: 0 21px 0 25px;
          min-width: 569px;
          .card-content {
            padding: 24.5px 0 24.5px 0;
            position: relative;
            + .card-content {
              border-top: 1px solid #e5eaf7;
            }
          }
        }
        .request {
          display: flex;
          align-items: center;
          .MuiAvatar-root {
            margin: 0 15px 0 0;
            width: 40px;
            height: 40px;
          }
          .request-header {
            width: calc(100% - 250px);
            .user-title {
              color: ${PRIMARY_COLOR};
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 15px;
              margin: 0 0 2px 0;
              padding-top: 4px;
            }
            .panel-sub-title {
              color: ${PRIMARY_COLOR};
              font-family: "TTCommons-Regular";
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
            }
          }
          .request-actions {
            margin-left: auto;
            button {
              border-radius: 45px;
              padding: 4px 10px 2px;
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              text-align: center;
              height: auto;
              min-width: 84px;
              .content {
                min-width: auto;
              }
              + button {
                margin-left: 20px;
              }
            }
          }
          + .request {
            margin-top: 24px;
            padding-top: 24px;
            border-top: 1px solid #e5eaf7;
          }
        }
      }
    }
  }
`;
