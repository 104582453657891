import React, { useState } from "react";
import { isUndefined } from "lodash";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ProfileFooter.module.scss";
import CustomSwitch from "../../CustomSwitch/CustomSwitch";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import { useAppState } from "../../../context";
import CustomDialog from "../../CustomDialog/CustomDialog";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { useUpdateProfile } from "../../../hooks/Profile/useUpdateProfile";
import InfoIconTooltip from "../../InfoIconTooltip/InfoIconTooltip";

import ViewIcon from "../../../assets/svg/ViewIcon";

const cx = createModuleStyleExtractor(styles);

export default function ProfileFooter({ tab = "student" }) {
  const {
    handleAccessibilitySave,
    handleVisibilitySave,
    handleDMVisibilitySave,
  } = useUpdateProfile();
  const { profile, updateProfileState } = useAppState("profile");
  const {
    accessibility_help = false,
    is_network_popup_shown = false,
    allow_chat_from_external_network = false,
  } = profile || {};

  const [showProfile, setShowProfile] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [visibleNetwork, setIsVisibleNetwork] = useState(
    is_network_popup_shown
  );
  const [visibleDM, setIsVisibleDM] = useState(
    allow_chat_from_external_network
  );
  const [isAccessible, setIsAccessible] = useState(accessibility_help);

  const handleChangeAccessiblity = () => {
    setIsAccessible(!isAccessible);
    localStorage.setItem("accessibilityHelp", !accessibility_help);
    updateProfileState({ accessibility_help: !accessibility_help });
    handleAccessibilitySave(!accessibility_help);
    setShowConfirmationPopup(false);
  };
  const handleChangeVisibility = () => {
    setIsVisibleNetwork(!is_network_popup_shown);
    updateProfileState({ is_network_popup_shown: !is_network_popup_shown });
    handleVisibilitySave(!is_network_popup_shown, !visibleNetwork);
  };

  const handleChangeDMVisibility = (e = {}) => {
    setIsVisibleDM(e?.target?.checked);
    updateProfileState({
      allow_chat_from_external_network: e?.target?.checked,
    });
    handleDMVisibilitySave(e?.target?.checked, !visibleDM);
  };

  const handleCloseAccessibilityPopup = (e) => {
    if (!isUndefined(e)) {
      e.preventDefault();
    }
    setShowConfirmationPopup(null);
  };

  return (
    <div className={cx("o-final-container")}>
      <ProfilePreview
        defaultState={showProfile}
        handleClose={() => setShowProfile(false)}
        tab={tab}
      />
      {showConfirmationPopup && (
        <CustomDialog
          id="accessibility-popup"
          open={showConfirmationPopup}
          title={
            isAccessible
              ? "Do you want to disable accessibility mode?"
              : "Do you want to enable accessibility mode?"
          }
          subTitle="At Interstride, it’s our mission to be inclusive and offer equal opportunities to all. We offer a customized version of the platform for those with accessibility needs."
          handleClose={(e) => handleCloseAccessibilityPopup(e)}
          className="signup-accessibility-popup"
          dialogTitleClass="modal-title"
          dialogSubTitleClass="text-para"
        >
          <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
            <div className="btn">
              <ButtonCustom
                width="238"
                height={50}
                onClick={() => handleChangeAccessiblity()}
              >
                Yes
              </ButtonCustom>
            </div>
            <a
              href="/#"
              className="text-grey cursor-pointer link-focus"
              style={{ marginTop: "30px" }}
              onClick={(e) => handleCloseAccessibilityPopup(e)}
              aria-label="No thanks"
            >
              No thanks
            </a>
          </div>
        </CustomDialog>
      )}
      <div className={cx("o-final-container__footer")}>
        {tab === "student" && (
          <>
            <div className={cx("o-final-container__footer__visibility")}>
              <span
                className={cx("o-final-container__footer__visibility__label")}
              >
                Profile visible to network
              </span>
              <CustomSwitch
                label="Profile visible to network"
                handleChange={handleChangeVisibility}
                checked={visibleNetwork}
                id="profile-visibility"
              />
              <InfoIconTooltip
                margin="0"
                placement="bottom"
                aria-label="Profile visible to network"
                content={
                  "Your profile is visible to students and alumni at your university, and to all Interstride users only when you engage in public communities. If deselected, your profile will be visible to no one."
                }
                className={cx("o-final-container__footer__visibility__info")}
              />
            </div>
            {visibleNetwork && (
              <div className={cx("o-final-container__footer__visibility")}>
                <span
                  className={cx("o-final-container__footer__visibility__label")}
                >
                  Allow DMs from all network
                </span>
                <CustomSwitch
                  label="Allow DMs from all network"
                  handleChange={handleChangeDMVisibility}
                  checked={visibleDM}
                  id="profile-visibility-dm"
                />
                <InfoIconTooltip
                  margin="0"
                  placement="bottom"
                  aria-label="Allow DMs from all network"
                  content={
                    "You may receive DMs from international students and alumni at your university and other Interstride schools. If selected, you may receive direct messages from international students and alumni at your university, as well as from other Interstride schools."
                  }
                  className={cx("o-final-container__footer__visibility__info")}
                />
              </div>
            )}
            <div className={cx("o-final-container__footer__accessibility")}>
              <div
                className={cx([
                  "o-final-container__footer__accessibility__label",
                  isAccessible
                    ? "o-final-container__footer__accessibility__label--active"
                    : "",
                ])}
              >
                <span>Accessibility mode</span>
              </div>
              <CustomSwitch
                label="Enable accessibility mode"
                handleChange={() => {
                  setShowConfirmationPopup(true);
                }}
                isPopupOpen={showConfirmationPopup}
                setShowConfirmationPopup={setShowConfirmationPopup}
                checked={isAccessible}
                hasModel={true}
                id="profile-accessibility"
              />
            </div>
          </>
        )}

        <div className="ambassador-profile-tutorial-two">
          <a
            href="/#"
            className={cx("o-final-container__footer__preview")}
            onClick={(e) => {
              e.preventDefault();
              setShowProfile(!showProfile);
            }}
          >
            <span className={cx("o-final-container__footer__preview__label")}>
              Preview profile
              <ViewIcon />
            </span>
          </a>
        </div>
      </div>
      <div className={cx("o-final-container__foot-note")}>
        <span>All mandatory fields are marked with an asterisk (*).</span>
      </div>
    </div>
  );
}
