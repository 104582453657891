import styled from "styled-components";
import {
  PRIMARY_COLOR,
  INACTIVE_BTN_GRAY_COLOR,
} from "../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-list {
    .search-top {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: space-between;
      .title-primary {
        display: flex;
      }
      .sort-by {
        span.sortby {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
        .react-custom-dropdown {
          color: ${PRIMARY_COLOR};
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          > div:nth-child(3) {
            margin: 0 !important;
            > div {
              margin: 0 !important;
              > div {
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                margin: 0 !important;
                overflow: visible !important;
                > div {
                  font-size: 15px;
                  letter-spacing: 0;
                  line-height: 17px;
                  margin: 0 !important;
                  > &:hover {
                    background: ${PRIMARY_COLOR};
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-item-tags {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      row-gap: 10px;
      margin-bottom: 10px;
      .tag-item {
        border-radius: 100px;
        background-color: #f1f4f7;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        padding: 4px 10px 0;
        display: inline-block;
        white-space: nowrap !important;
      }
    }
    .search-item {
      display: flex;
      flex-wrap: no-wrap;
      align-items: center;
      &.featured-job {
        .content {
          .header-primary {
            align-items: center;
          }
        }
      }
      .custom-rss-icon {
        height: 15px;
        width: 15px;
        position: absolute;
        margin-top: -49px;
        margin-left: -20px;
      }
      .avtar {
        margin-right: 10px;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          border-radius: 100px;
        }
      }

      .apply-btn {
        margin-left: 10px;
        .nlink-btn {
          min-width: 80px;
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          padding: 2px 8px 0;
        }
        .bg-gray {
          color: #ffffff;
          background: var(--gray-color);
          &:hover {
            background: var(--gray-color-btn-hover);
          }
          &:disabled {
            background: ${INACTIVE_BTN_GRAY_COLOR};
            color: #ffff;
          }
        }
      }
      .content {
        margin-right: auto;
        max-width: calc(100% - 170px);
        .header-primary {
          padding-top: 4px;
          line-height: 16px;
          span {
            white-space: normal;
            word-break: break-word;
          }

          span.inline-title {
            overflow: hidden;
            line-height: 17px;
            margin-bottom: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          span.inline-title-flex {
            width: max-content;
          }
        }
        .star {
          margin: -3px 0 0 10px;
        }
      }
      .sub-header-primary {
        margin-bottom: -1px;
      }
      .text-grey {
        padding-top: 4px;
        line-height: 16px;
      }
    }
    .pl-8 {
      padding-left: 8px;
    }
    .card-actions-wrapper {
      display: flex;
      align-items: center;
      /* padding-left: 8px; */
      opacity: 0;
      visibility: hidden;
      &.show-actions {
        opacity: 1;
        visibility: visible;
      }
      @media (max-width: 1024px) {
        opacity: 1;
        visibility: visible;
      }
      .nlink-btn {
        width: auto;
        min-width: 80px;
        white-space: nowrap;
      }
      div {
        + div {
          margin-left: 12px;
          @media (max-width: 991px) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .card-wrapper {
    .MuiPaper-root {
      padding: 18px 15px !important;
      transition: all 0.3s;
      min-height: 100px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (max-width: 767px) {
        min-height: auto;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
        .search-item {
          .card-actions-wrapper {
            opacity: 1;
            visibility: visible;
            transition: all 0.3s;
          }
        }
      }
      .MuiCardContent-root {
        padding: 0;
        width: 100%;
      }
    }
  }

  .empty-alerts-box {
    .card-content {
      padding: 39px 18px 30px 18px;
      box-sizing: border-box;
    }
    .empty-item {
      text-align: center;
      .header-primary {
        font-size: 22px;
        line-height: 21px;
        margin: 0 0 10px 0;
      }
      .text-grey {
        font-size: 18px;
        line-height: 17px;
        font-family: "TTCommons-Medium";
      }
    }
  }

  .clearable-filter-container {
    .tags-wrapper {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      align-items: flex-end;
      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 40px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
        padding: 3px 8px 0 12px;
        span {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 19px;
        }
        img {
          width: 20px;
          margin-left: 6px;
          cursor: pointer;
          position: relative;
          top: -2px;
        }
      }
    }
  }
`;
