import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .notification-dialog-info-panel-custom {
    .info-panel-card {
      min-height: 80px;
      .card-content {
        .panel-header {
          width: calc(100% - 85px) !important;
          .panel-title {
            + .panel-title {
              display: flex;
              align-items: center;
              padding: 0;
              margin: 2px 0 0 0;
              .tags {
                margin: 0;
                .tag-item {
                  white-space: nowrap;
                  margin-right: 8px;
                  padding: 4px 9px 0;
                  min-width: 50px;
                  line-height: 21px;
                }
              }
              .panel-sub-title {
                padding: 0;
                margin: 5px 0 0 0;
              }
            }
          }
        }
      }
    }
  }
  .notification-dialog-setting-close-icon-wrapper {
    .notification-setting {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }
  }
`;
