import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

export const getGameplanDashboardDataAPI = () => {
  return axiosInstance
    .get(`gameplan/fetch_score`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getTestQuestionsAPI = (student_type) => {
  return axiosInstance
    .get(
      `gameplan/fetch_questions?student_type=${student_type}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

export const submitTestAnswersAPI = (formData) => {
  return axiosInstance
    .post(
      `gameplan/submit_test`,
      formData,
      headers_with_token({ formData: true })
    )
    .then((res) => {
      return res.data;
    });
};
