import React from "react";
import { createModuleStyleExtractor } from "../../utils/css";
import styles from "./ProfileModule.module.scss";
import InfoIconTooltip from "../../components/InfoIconTooltip/InfoIconTooltip";

const cx = createModuleStyleExtractor(styles);

export const MiniHeading = ({ heading, showIcon = false, iconContent }) => {
  return (
    <h1 className={cx("section__top-heading")}>
      {heading}
      {showIcon && (
        <InfoIconTooltip
          aria-label={heading}
          content={iconContent}
          margin="0px 0px 0px 5px"
          placement="bottom"
        />
      )}
    </h1>
  );
};
