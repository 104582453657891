import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import cls from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { makeStyles } from "@mui/styles";

import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";

import Loader from "../../../components/Loader/Loader";
import { config } from "../../../constant/config";
import { useAppState } from "../../../context";
import {
  NoUserImage,
  capitalizeFirstLetterOnType,
  toastify,
} from "../../../helper/helper";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getDepartmentList,
  signUpSSO,
  updateUserProfileDetails,
} from "../../../services/authenticationServices";
import { getMajorList } from "../../../services/educationServices";
import {
  changeSecondaryToPrimary,
  disconnectAccountLinkedin,
  updateProfileImage,
} from "../../../services/profile.service";
import { resetOPTInitialState } from "../../../redux/reducers/optReducer";

import {
  months,
  signOutCall,
  validateEmail,
  STUDENT_VIEW_PREFERENCES_OPTIONS,
} from "../../../utils/common";

import CropperPic from "../../Cropper/index";
import { SecondaryEmailConfirmDialog } from "../../SecondaryEmailConfirmDialog/SecondaryEmailConfirmDialog";

import DisconnectLinkedinDialogue from "../DisconnectLinkedinDialogue/index";
import EmailUpdateDialog from "../EmailUpdateDialog";
import { CustomDialogBox, FirstLoginStyles } from "./FirstLoginStyles";
import SSOForm from "./SSOForm";
import { PRIMARY_COLOR } from "../../../styles/variables";
import { setUserProfileAttachment } from "../../../redux/reducers/profileReducer";
import {
  updateUsersLocalState,
  updateUsersPersistState,
} from "../../../redux/reducers/userReducer";

const LINKEDIN_REDIRECT_URI = config().LINKEDIN_REDIRECT_URI;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "0 1px 14px 3px rgba(128,148,171,0.4)",
    borderRadius: "10px",
    overflow: "hidden",
  },
  dialogTitle: {
    color: PRIMARY_COLOR,
    fontSize: "30px",
    fontFamily: "TTCommons-Medium",
    maxWidth: "355px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    lineHeight: "31px",
    fontWeight: "500",
    margin: "0px",
  },
}));

const FirstLogin = ({
  mediaQuery,
  open = false,
  handleClose = () => {},
  className,
  ...props
}) => {
  const dispatch = useDispatch();
  const { resetProfileState } = useAppState("profile");
  const { updateAmbassadorProfileState } = useAppState("ambassadorProfile");

  const { users = {}, users_local = {} } = useSelector(
    (store) => store.userStore
  );
  const { userProfileAttachement } = useSelector((store) => store.profileStore);
  const formRef = useRef();
  const [profilePicURL, setProfilePicURL] = useState(NoUserImage);
  const { id: userId, admission_user_type } = users || {};

  const [callSignUpSSO, refreshSignUpSSO] = usePromise(signUpSSO);
  //const [, refreshCheckSsoUser] = usePromise(checkSsoUser);
  const [showProfilePicPopup, setProfilePicPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [cropperImage, setCropperImage] = useState();
  const [updateEmailPopUp, setUpdateEmailPopUp] = useState(false);
  const [disconnectLinkedinPopUp, setDisconnectLinkedinPopUp] = useState(false);
  const [searchedCities, setSearchedCities] = useState([]);
  const [switchEmail, setSwitchEmail] = useState(false);
  const [accessibilityHelp, setAccessibilityHelp] = useState(
    localStorage.getItem("accessibilityHelp") === "true" ? true : false
  );
  const classes = useStyles();
  const [country, setCountry] = useState("");
  const [callUpdateUserProfileDetails, refreshUpdateUserProfileDetails] =
    usePromise(updateUserProfileDetails);
  const { school_id } = users_local || {};
  const [departments, setDepartments] = useState(null);
  const [majors, setMajors] = useState([]);
  const [graduationYear, setGraduationYear] = useState(
    users_local?.educations?.[0]?.graduation_year ?? ""
  );
  const [graduationMonth, setGraduationMonth] = useState(
    users_local?.educations?.[0]?.graduation_month ?? ""
  );
  const [profilePhoto, setProfilePhoto] = useState(
    users_local?.user_profile_url
  );
  const [isProcessing, setIsProcessing] = useState(false);

  const updateUserDetails = (name, value) => {
    if (name === "account_type") {
      dispatch(
        updateUsersLocalState({
          [name]: value,
          educations: [
            ...((users_local?.educations ?? []).map((item, index) =>
              index === 0
                ? { ...item, graduation_date: "", graduation_year: "" }
                : item
            ) || []),
          ],
        })
      );
    } else {
      dispatch(updateUsersLocalState({ [name]: value }));
    }
  };

  useEffect(() => {
    dispatch(updateUsersPersistState({ user_profile_url: profilePhoto }));
    try {
      dispatch(
        updateUsersLocalState({
          ...users_local,
          user_profile_url: profilePhoto,
        })
      );
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePhoto]);

  useEffect(() => {
    // Fetch city options for default value
    if (users_local?.current_city)
      getPlacePredictions({ input: users_local?.current_city });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllMajors = async () => {
    try {
      const response = await getMajorList();
      if (response?.success) {
        setMajors(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeToPrimary = async () => {
    if (!users_local?.secondary_email) {
      toastify("error", "Please enter secondary email first");
      return;
    }
    if (!validateEmail(users_local?.secondary_email)) {
      toastify("error", "Please enter valid email");
      return;
    }
    setSwitchEmail(true);
  };

  const handleEmailSwitch = async () => {
    setSwitchEmail(false);
    try {
      const response = await changeSecondaryToPrimary(
        users_local?.secondary_email
      );
      const { success, message } = response || {};
      if (success) {
        toastify("error", message ?? "Oops! Something went wrong");
        // Logout the user
        if (users_local != null) {
          dispatch(resetOPTInitialState());
          resetProfileState();
          signOutCall(users_local.school_id);
        }
      }
    } catch (error) {
      const { data } = error;
      toastify("error", data?.message ?? "Oops! Something went wrong");
    }
  };

  useEffect(() => {
    fetchAllMajors();
  }, []);

  useEffect(() => {
    try {
      if (callUpdateUserProfileDetails.hasFetched()) {
        if (
          callUpdateUserProfileDetails.hasErrors() === false &&
          callUpdateUserProfileDetails.data().message &&
          callUpdateUserProfileDetails.data().data
        ) {
          toastify("success", callUpdateUserProfileDetails.data().message);
          dispatch(
            updateUsersPersistState({
              ...callUpdateUserProfileDetails.data()?.data?.users,
            })
          );
          handleClose();
          // Open ambassador profile popup if user is ambassador
          if (admission_user_type) {
            updateAmbassadorProfileState({ ambassadorPopup: true });
          }
        } else if (
          callUpdateUserProfileDetails.hasErrors() === true &&
          callUpdateUserProfileDetails?.error()?.data
        ) {
          toastify(
            "error",
            callUpdateUserProfileDetails?.error()?.data?.message
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callUpdateUserProfileDetails.isFetching()]);

  const handleCallback = useCallback((file) => {
    file.forEach((item) => {
      if (!["image/png", "image/jpg", "image/jpeg"].includes(item.type)) {
        toastify("error", "Please select a valid image (png, jpg, jpeg)");
      } else if (parseInt(item.size / 1000) > 5000) {
        toastify("error", "Please select a file less than 5mb");
      } else {
        if (accessibilityHelp) {
          setProfilePicURL(URL.createObjectURL(item));
          dispatch(setUserProfileAttachment(item));
        } else {
          setCropperImage(URL.createObjectURL(item));
        }
        setProfilePicPopup(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ref, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: "AIzaSyDuoUTXtcaLFxHyaGxS3K4BnGjEBBsMo7Y",
    onPlaceSelected: (place) => {
      console.log(place);
    },
    options: {
      types: ["locality"],
      // componentRestrictions: { locality: true },
    },
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    let allCities = [];

    placePredictions.map((city) => {
      const obj = {
        label: city.structured_formatting.main_text,
        value: city.structured_formatting.main_text,
      };
      allCities.push(obj);
    });
    let uniqueCities = allCities
      .map((e) => e.label)
      .filter((item, ind, self) => {
        return self.indexOf(item) === ind;
      })
      .map((e) => ({ label: e, value: e }));
    if (uniqueCities.length) setSearchedCities(uniqueCities);
  }, [placePredictions]);

  const { formProps } = props;

  const handleSuccess = (data) => {
    refreshSignUpSSO(userId, "linkedin", data.code, LINKEDIN_REDIRECT_URI);
    setIsProcessing(false);
  };

  const handleFailure = (error) => {
    toastify("error", error.errorMessage);
  };

  const handleLinkedinDisconnect = async () => {
    try {
      const response = await disconnectAccountLinkedin();
      if (response.success) {
        toastify("success", response.message);
        dispatch(updateUsersPersistState({ is_linkedin_connected: false }));
      }
    } catch (error) {
      console.error(error);
      toastify(
        "error",
        error?.data?.message ? error.data.message : "Oops! Something went wrong"
      );
    }
  };

  useEffect(() => {
    if (callSignUpSSO.hasFetched()) {
      setIsProcessing(true);
      if (callSignUpSSO.hasErrors()) {
        toastify(
          "error",
          callSignUpSSO.error()?.data?.message
            ? callSignUpSSO.error().data.message
            : "Oops! something went wrong"
        );
        setIsProcessing(false);
      } else if (callSignUpSSO.data()) {
        toastify("success", "Successfully connected to LinkedIn");
        // Update useApp state
        dispatch(updateUsersPersistState({ is_linkedin_connected: true }));
        setIsProcessing(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSignUpSSO.isFetching()]);

  const updateProfilePic = async (file) => {
    setShowLoader(true);

    const formData = new FormData();

    formData.append("user_profile", file ? file : userProfileAttachement);
    formData.append("remove", false);

    try {
      //updateUserProfilePic
      const response = await updateProfileImage(formData);
      if (response?.success) {
        setProfilePhoto(response.profile_image_url);
      }
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const getCropData = (file, url) => {
    setProfilePicURL(url);
    dispatch(setUserProfileAttachment(file));
    if (file && url) {
      updateProfilePic(file);
      setProfilePicPopup(false);
    }
  };

  const onSaveProfilePic = () => {
    updateProfilePic(userProfileAttachement);
    setProfilePicPopup(false);
  };

  const [rerender, setRerender] = useState(true);

  useEffect(() => {
    setRerender(true);
  }, [rerender]);

  const handleCountryChange = (country) => {
    dispatch(
      updateUsersLocalState({
        nationality: capitalizeFirstLetterOnType(country),
        current_city: "",
        student_view_preference:
          country === "United States"
            ? STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC
            : STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
      })
    );
    setCountry(capitalizeFirstLetterOnType(country));
    setRerender(false);
  };

  const errorCallback = (error) => {
    const { message } = error;
    if (message) {
      toastify("error", message);
      setIsProcessing(false);
    }
  };

  const saveProfile = () => {
    const requestBody = {
      user: {
        ...users_local,
        first_login: false,
        educations: [...users_local.educations],
        first_name: users_local?.first_name,
        last_name: users_local?.last_name,
        nationality: users_local?.nationality,
        current_city: users_local?.current_city,
        department: users_local?.educations[0]?.department,
        degree: users_local?.educations[0]?.degree,
        major: users_local?.educations[0]?.major,
        secondary_email: users_local?.secondary_email,
        graduation_month: `${graduationMonth}`,
        graduation_year: `${graduationYear}`,
        ...(users_local?.account_type && {
          account_type: users_local.account_type.toLowerCase(),
        }),
        accessibility_help: accessibilityHelp,
        educations_attributes: [
          {
            id: users_local?.educations[0]?.id,
            department: users_local?.educations[0]?.department,
            degree: users_local?.educations[0]?.degree,
            major: users_local?.educations[0]?.major,
            graduation_date: `${graduationMonth}-${graduationYear}`,
            graduation_month: `${graduationMonth}`,
            graduation_year: `${graduationYear}`,
          },
        ],
      },
    };
    refreshUpdateUserProfileDetails(
      userId,
      requestBody,
      () => setIsProcessing(false),
      errorCallback
    );
  };

  const handlePreSubmitValidations = () => {
    if (
      (departments?.length > 0 && !users_local?.educations[0]?.department) ||
      !graduationMonth ||
      !graduationYear ||
      getGraduationMonthError() ||
      !users_local?.educations[0]?.degree ||
      !users_local?.educations[0]?.major
    ) {
      return false;
    }
    return true;
  };

  const handleFormSubmit = () => {
    if (
      users_local?.account_type?.toLowerCase() !== "staff" &&
      !handlePreSubmitValidations()
    ) {
      return;
    }

    setIsProcessing(true);
    saveProfile();
  };

  const handleDepartmentChange = (value, departmentObject = null) => {
    if (value) {
      dispatch(
        updateUsersLocalState({
          educations: [
            ...((users_local?.educations ?? []).map((item, index) =>
              index === 0 ? { ...item, department: value } : item
            ) || []),
          ],
          department_ids: departmentObject?.id ? [departmentObject.id] : [],
        })
      );
    }
  };

  const handleDegreeChange = (degree) => {
    if (degree) {
      dispatch(
        updateUsersLocalState({
          educations: [
            ...(users_local.educations.map((item, index) =>
              index === 0 ? { ...item, degree } : item
            ) || []),
          ],
          degree,
        })
      );
    }
  };

  const handleMajorChange = (major) => {
    if (major) {
      dispatch(
        updateUsersLocalState({
          educations: [
            ...(users_local.educations.map((item, index) =>
              index === 0 ? { ...item, major } : item
            ) || []),
          ],
          major,
        })
      );
    }
  };

  const handleMonthChange = (val) => {
    setGraduationMonth(val.value);
    if (formRef?.current) {
      formRef.current.setFieldTouched("graduation_month", true);
    }

    if (!users_local?.educations[0]) {
      return;
    }
    try {
      updateUsersLocalState({
        educations: [
          ...(users_local.educations.map((item, index) =>
            index === 0
              ? {
                  ...item,
                  graduation_date: val.value + " " + item?.graduation_year,
                  graduation_month: val.value,
                  graduation_year: graduationYear,
                }
              : item
          ) || []),
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getGraduationMonthError = () => {
    if (!graduationMonth) return "Month required";
    let currentMonth = new Date().getMonth();
    let account_type = users_local?.account_type?.toLowerCase();
    const selectedMonthIndex = months.indexOf(graduationMonth);
    if (
      Number(graduationYear) === new Date().getFullYear() &&
      ((account_type === "student" && selectedMonthIndex < currentMonth) ||
        (account_type === "alumni" && selectedMonthIndex >= currentMonth))
    ) {
      return "Invalid month";
    }

    return "";
  };

  const fetchDepartmentsList = async () => {
    const response = await getDepartmentList(school_id);
    if (response?.success) {
      const { data } = response || {};
      if (data) {
        setDepartments([...data?.schools] || []);
      }
    }
  };

  const getDefaultYear = () => {
    if (graduationYear) {
      return graduationYear;
    }
    if (
      users_local.educations[0]?.graduation_date &&
      moment(users_local.educations[0]?.graduation_date).isValid()
    ) {
      return moment(users_local.educations[0].graduation_date).format("YYYY");
    }
    return "";
  };

  const handleYearChange = (val) => {
    setGraduationYear(val.value);
    if (formRef?.current) {
      formRef.current.setFieldTouched("graduation_year", true);
    }
    try {
      dispatch(
        updateUsersLocalState({
          educations: [
            ...(users_local.educations.map((item, index) =>
              index === 0
                ? {
                    ...item,
                    graduation_year: val.value,
                    graduation_date: item.graduation_month + " " + val.value,
                  }
                : item
            ) || []),
          ],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getGYError = () => {
    if (!users_local?.educations[0]?.graduation_year) {
      return `Year required`;
    }
    if (
      users_local?.account_type?.toLowerCase() === "alumni" &&
      Number(users_local?.educations[0]?.graduation_year) > currentYear
    ) {
      return `Invalid Year`;
    } else if (
      users_local?.account_type?.toLowerCase() === "student" &&
      Number(users_local?.educations[0]?.graduation_year) < currentYear
    ) {
      return `Invalid Year`;
    }
    return "";
  };

  useEffect(() => {
    if (users_local) {
      setCountry(users_local?.nationality);
    }
  }, [users_local]);

  useEffect(() => {
    if (school_id) {
      fetchDepartmentsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school_id]);

  const currentYear = new Date().getFullYear();

  const [currentStep, setCurrentStep] = useState(1);
  const changeStep = () => {
    setCurrentStep(currentStep + 1);
    let fieldsTouched = { first_name: true, last_name: true };
    if (currentStep === 2) {
      fieldsTouched = { ...fieldsTouched, secondary_email: true };
    } else if (currentStep === 3) {
      fieldsTouched = {
        ...fieldsTouched,
        secondary_email: true,
        nationality: true,
        current_city: true,
      };
    }
    formRef.current.setTouched({ ...fieldsTouched });
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      className={cls(className, classes.root)}
      maxWidth={mediaQuery ? "xs" : false}
      scroll={"body"}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="modal-title"
      TransitionProps={{ role: "presentation" }}
    >
      <div className="loggedin-profile-modal">
        <div className="m-header">
          {users_local?.account_type?.toLowerCase() === "staff" ? (
            <h2
              id="modal-title"
              className={`${classes.dialogTitle} modal-title`}
            >
              Finalize your login
            </h2>
          ) : (
            <h2
              id="modal-title"
              className={`${classes.dialogTitle} modal-title`}
            >
              {currentStep === 1
                ? "Finalize your login"
                : currentStep === 2
                ? "You're almost there!"
                : "Last step!"}
            </h2>
          )}
        </div>
        {users_local?.account_type?.toLowerCase() !== "staff" && (
          <p className="p-info">Step {currentStep} of 3</p>
        )}
        <div className="m-body">
          <FirstLoginStyles>
            <div className="profile-info">
              <SSOForm
                ref={ref}
                formRef={formRef}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                country={country}
                changeStep={changeStep}
                formProps={formProps}
                handleFormSubmit={handleFormSubmit}
                handleSuccess={handleSuccess}
                handleFailure={handleFailure}
                handleLinkedinDisconnect={handleLinkedinDisconnect}
                updateUserDetails={updateUserDetails}
                setUpdateEmailPopUp={setUpdateEmailPopUp}
                handleChangeToPrimary={handleChangeToPrimary}
                callSignUpSSO={callSignUpSSO}
                showLoader={showLoader}
                mediaQuery={mediaQuery}
                handleCallback={handleCallback}
                handleCountryChange={handleCountryChange}
                rerender={rerender}
                getPlacePredictions={getPlacePredictions}
                setSearchedCities={setSearchedCities}
                searchedCities={searchedCities}
                handleDegreeChange={handleDegreeChange}
                departments={departments}
                handleDepartmentChange={handleDepartmentChange}
                majors={majors}
                handleMajorChange={handleMajorChange}
                graduationMonth={graduationMonth}
                graduationYear={graduationYear}
                profilePhoto={profilePhoto}
                handleMonthChange={handleMonthChange}
                getGraduationMonthError={getGraduationMonthError}
                getDefaultYear={getDefaultYear}
                handleYearChange={handleYearChange}
                getGYError={getGYError}
                accessibilityHelp={accessibilityHelp}
                setAccessibilityHelp={setAccessibilityHelp}
              />
              {
                <SecondaryEmailConfirmDialog
                  isShow={switchEmail}
                  setIsShow={setSwitchEmail}
                  onSuccess={handleEmailSwitch}
                />
              }
              {updateEmailPopUp && (
                <CustomDialogBox
                  open={updateEmailPopUp}
                  title={"Change email address"}
                  handleClose={() => setUpdateEmailPopUp(false)}
                  isDisplayCloseButton={true}
                  className="profile-accessibility-popup"
                  dialogTitleClass="modal-title"
                  dialogSubTitleClass="text-para"
                >
                  <EmailUpdateDialog
                    setUpdateEmailPopUp={(e) => setUpdateEmailPopUp(e)}
                  />
                </CustomDialogBox>
              )}
              {disconnectLinkedinPopUp && (
                <CustomDialogBox
                  open={disconnectLinkedinPopUp}
                  title={"Disconnect LinkedIn account"}
                  subTitle={
                    'By clicking on "Yes" button, You will be signed out from all of your sessions which are logged in using this LinkedIn account. Do you want to continue to disconnect LinkedIn Account?'
                  }
                  handleClose={() => setDisconnectLinkedinPopUp(false)}
                  isDisplayCloseButton={true}
                  className="profile-accessibility-popup"
                  dialogTitleClass="modal-title"
                  dialogSubTitleClass="text-para"
                >
                  <DisconnectLinkedinDialogue
                    setDisconnectLinkedinPopUp={(e) =>
                      setDisconnectLinkedinPopUp(e)
                    }
                  />
                </CustomDialogBox>
              )}
              {showProfilePicPopup && (
                <CustomDialog
                  open={showProfilePicPopup}
                  title={"Upload a profile picture"}
                  handleClose={() => {
                    setProfilePicPopup(false);
                    dispatch(setUserProfileAttachment(null));
                  }}
                  isDisplayCloseButton={true}
                  className={`${
                    accessibilityHelp ? "accessibility" : ""
                  } profile-pic-popup`}
                  dialogTitleClass="modal-title"
                  dialogSubTitleClass="text-para"
                >
                  <div className="model-body d-flex justify-content-center align-items-center flex-flow-column">
                    {accessibilityHelp ? (
                      <>
                        <div className="accessibility profile-upload">
                          <div className="profile-img-wrap">
                            <img
                              className="profile-img"
                              src={profilePicURL}
                              alt="Profile"
                              height={200}
                              width={200}
                            />
                          </div>
                        </div>
                        <div className="customized-button">
                          <ButtonCustom
                            isDisabled={
                              !userProfileAttachement ||
                              userProfileAttachement === null
                            }
                            onClick={onSaveProfilePic}
                          >
                            Save
                          </ButtonCustom>
                        </div>
                        <a
                          href="/#"
                          className="text-grey cursor-pointer link-focus"
                          onClick={(e) => {
                            e.preventDefault();
                            setProfilePicPopup(false);
                            dispatch(setUserProfileAttachment(null));
                          }}
                          aria-label="cancel"
                        >
                          Cancel
                        </a>
                      </>
                    ) : (
                      <>
                        {cropperImage && (
                          <>
                            <CropperPic
                              image={cropperImage}
                              getCropData={getCropData}
                              profileInfo={true}
                            />
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </CustomDialog>
              )}
            </div>
            {isProcessing && (
              <div>
                <Loader />
              </div>
            )}
          </FirstLoginStyles>
        </div>
      </div>
    </Dialog>
  );
};

export default FirstLogin;
