import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerThumbnail } from "react-shimmer-effects";

import CompanyDetailStyles from "./CompanyDetailStyles";
import SearchResult from "../../../../components/Jobs/Visa/SearchResult/SearchResult";
import CompanyInfo from "../../../../components/Jobs/Visa/CompanyInfo/CompanyInfo";
import FilterPositions from "../../../../components/Jobs/Visa/FilterPositions/FilterPositions";
import {
  updateFavouriteCompanyAPI,
  getSavedCompaniesAPI,
  getJobsCompanyDetailsAPI,
} from "../../../../services/JobServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { toastify } from "../../../../helper/helper";
import SelectiveSavedCompanies from "../../../../components/Jobs/Visa/SelectiveSavedCompanies/SelectiveSavedCompanies";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";
import { routes } from "../../../../routes";

const CompanyDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appliedVisaFilters = {} } = useSelector((store) => store.jobStore);
  let query = new URLSearchParams(useLocation().search);
  let saved = query.get("saved");
  let company_id = query.get("company_id");
  let employer_name = query.get("employer_name");

  const redirectedFromJobs = company_id || employer_name;

  const showPetition =
    appliedVisaFilters.visa &&
    (appliedVisaFilters.visa === "h1b" ||
      appliedVisaFilters.visa === "greencard");

  const [activeTab, setActiveTab] = useState(
    showPetition || redirectedFromJobs ? "petition" : "position"
  );
  const [positionFilters, setPositionFilters] = useState({
    country: "us",
  });
  const [refreshJobs, setRefreshJobs] = useState(1);
  const [savedCompaniesList, setSavedCompaniesList] = useState([]);
  const [callSavedCompaniesAPI, refreshSavedCompaniesAPI] =
    usePromise(getSavedCompaniesAPI);
  const [callingUpdateFavouriteCompanyAPI, refreshUpdateFavouriteCompanyAPI] =
    usePromise(updateFavouriteCompanyAPI);
  const [callGetJobsCompanyDetailsAPI, refreshGetJobsCompanyDetailsAPI] =
    usePromise(getJobsCompanyDetailsAPI);
  const [showLoader, setShowLoader] = useState(redirectedFromJobs);

  useEffect(() => {
    if (redirectedFromJobs)
      refreshGetJobsCompanyDetailsAPI(company_id, employer_name);
    refreshSavedCompaniesAPI();
  }, []);

  useEffect(() => {
    if (
      callGetJobsCompanyDetailsAPI.hasFetched() &&
      callGetJobsCompanyDetailsAPI.hasErrors() === false
    ) {
      if (
        callGetJobsCompanyDetailsAPI.data() &&
        callGetJobsCompanyDetailsAPI.data().data
      ) {
        const companyDetails = callGetJobsCompanyDetailsAPI.data().data;
        dispatch(
          setSelectedCompany({
            company_id,
            employer_name,
            tab_type: companyDetails?.company_type,
            ...companyDetails,
          })
        );
        setShowLoader(false);
      } else {
        navigate(`${routes.JOBS.VISA}?type=company`);
      }
    } else if (
      callGetJobsCompanyDetailsAPI.hasFetched() &&
      callGetJobsCompanyDetailsAPI.hasErrors() === true
    ) {
      navigate(`${routes.JOBS.VISA}?type=company`);
    }
  }, [callGetJobsCompanyDetailsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateFavouriteCompanyAPI.hasFetched() &&
      callingUpdateFavouriteCompanyAPI.hasErrors() === false
    ) {
      refreshSavedCompaniesAPI();
      toastify("success", callingUpdateFavouriteCompanyAPI.data().message);
    }
  }, [callingUpdateFavouriteCompanyAPI.isFetching()]);

  useEffect(() => {
    if (
      callSavedCompaniesAPI.hasFetched() &&
      callSavedCompaniesAPI.hasErrors() === false
    ) {
      if (callSavedCompaniesAPI.data() && callSavedCompaniesAPI.data().data) {
        setSavedCompaniesList(
          callSavedCompaniesAPI.data().data.favorite_companies
        );
      }
    }
  }, [callSavedCompaniesAPI.isFetching()]);

  const updateFavourites = ({ company_id, tab_type, employer_name, like }) => {
    refreshUpdateFavouriteCompanyAPI({
      tab_type:
        tab_type && like === "unlike" ? tab_type : appliedVisaFilters.visa,
      company_id,
      company_name: employer_name,
      like,
    });
  };

  useEffect(() => {
    if (activeTab === "petition")
      setPositionFilters({
        country: "us",
      });
  }, [activeTab]);

  return (
    <CompanyDetailStyles>
      <div className="company-mainpage">
        {showLoader ? (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ShimmerThumbnail height={500} />
            </Grid>
            <Grid item xs={6}>
              <ShimmerThumbnail height={500} />
            </Grid>
            <Grid item xs={3}>
              <ShimmerThumbnail height={500} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {activeTab === "petition" ? (
                <>
                  {saved ? (
                    <SelectiveSavedCompanies
                      back2={"searchList"}
                      savedCompaniesList={savedCompaniesList}
                      updateFavourites={updateFavourites}
                    />
                  ) : (
                    <SearchResult
                      back2={redirectedFromJobs ? "jobs" : "searchList"}
                      savedCompaniesList={savedCompaniesList}
                      updateFavourites={updateFavourites}
                      fixedHeight
                    />
                  )}
                </>
              ) : (
                <FilterPositions
                  filter={positionFilters}
                  setFilter={(changedFilters) => {
                    setPositionFilters({
                      ...positionFilters,
                      ...changedFilters,
                    });
                  }}
                  applyFilters={() => setRefreshJobs(refreshJobs + 1)}
                  onClearAllClick={() => {
                    setPositionFilters({
                      search: "",
                      state: "",
                      city: "",
                      job_type: "",
                      publish_date: "",
                      country: "us",
                    });
                    setRefreshJobs(refreshJobs + 1);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <CompanyInfo
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filters={positionFilters}
                refreshJobs={refreshJobs}
                savedCompaniesList={savedCompaniesList}
                updateFavourites={updateFavourites}
                showPetition={showPetition}
              />
            </Grid>
          </Grid>
        )}
      </div>
      {/* </MainLayout> */}
    </CompanyDetailStyles>
  );
};
export default CompanyDetail;
