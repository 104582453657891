import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { filter, values } from "underscore";
import {
  ShimmerCategoryItem,
  ShimmerText,
  ShimmerTitle,
  ShimmerButton,
} from "react-shimmer-effects";
import moment from "moment";
import { get, isNull } from "lodash";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardRightSidebarStyles from "./DashboardRightSidebarStyles";

import CardCustom from "../../CardCustom/CardCustom";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import { routes } from "../../../routes";
import EventCard from "../../EventCard/EventCard";
import usePromise from "../../../hooks/usePromise/usePromise";
import { useAppState } from "../../../context";
import {
  calculateLocalEndTimeFromEpoch,
  getLocalTzAbbr,
} from "../../../helper/helper";
import { EmptyMyWebinar } from "../../Learn/Comman/MyWebinar/MyWebinar";
import CustomDialog from "../../CustomDialog/CustomDialog";
import EventList from "../../Tools/EventsList/EventList";
import { Mixpanel } from "../../../mixpanel";

import facebookIcon from "../../../assets/SocialIcon/updatedIcons/facebook.svg";
import linkedinIcon from "../../../assets/SocialIcon/updatedIcons/linkedin.svg";
import twitterIcon from "../../../assets/SocialIcon/updatedIcons/twitter.svg";
import BoostPopup from "../../../components/BoostDialog/BoostDialog";
import RecommendedNetworkCard from "../../RecommendedNetworkCard/RecommendedNetworkCard";
import {
  getUpcomingWebinarsAPI,
  getMyWebinarsAPI,
} from "../../../services/webinarServices";
import { getRecommendedNetworksAPI } from "../../../services/networkServices";
import { getPromoteAPI } from "../../../services/promoteService";
import {
  getEventList,
  getAppointments,
} from "../../../services/calendarServices";
import { getJobsCountriesAPI } from "../../../services/JobServices";
import ChromeExtensionSlide from "./CustomSliders/ChromeExtensionSlide/ChromeExtensionSlide";
import MobileAppSlide from "./CustomSliders/MobileAppSlide/MobileAppSlide";
import OPTCalculculatorSlide from "./CustomSliders/OPTCalculculatorSlide/OPTCalculculatorSlide";
import { STUDENT_VIEW_PREFERENCES_OPTIONS } from "../../../utils/common";
import { config } from "../../../constant/config";
import { setJobsCountryList } from "../../../redux/reducers/jobReducer";

const JOBS_COUNTRY_LIST_VERSION = config().VERSIONS.JOBS_COUNTRY_LIST;

const DashboardRightSidebar = ({ isResponsive = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { jobsCountryListVersion, jobsCountryList = [] } = useSelector(
    (store) => store.jobStore
  );
  const { permissions = {}, users = {} } = useSelector(
    (store) => store.userStore
  );
  const { discoverNetworks, setDiscoverNetworks } = useAppState("network");
  const { nationality = "United States" } = users;
  const { setCalendarEvents, setCalendarAppointments, upCommingEvents } =
    useAppState("tools");
  const { statusList, setStatusList } = useAppState("boostProfile");
  const { myWebinars, setMyWebinars } = useAppState("learn");

  const [callGetUpcominWebinarsAPI, refreshGetUpcomingWebinarsAPI] = usePromise(
    getUpcomingWebinarsAPI
  );
  const [callingGetMyWebinarsAPI, refreshGetMyWebinarsAPI] =
    usePromise(getMyWebinarsAPI);
  const [callPromoteAPI, refreshPromoteAPI] = usePromise(getPromoteAPI);
  const [callgetEventList, refreshgetEventList] = usePromise(getEventList);
  const [callgetAppointment, refreshgetAppointmentList] =
    usePromise(getAppointments);
  const [
    callingGetRecommendedNetWorkPeopleAPI,
    refreshGetRecommendedNetWorkPeopleAPI,
  ] = usePromise(getRecommendedNetworksAPI);
  const [callingGetJobsCountriesAPI, refreshGetJobsCountriesAPI] =
    usePromise(getJobsCountriesAPI);

  const [totalTrueValues, settotalTrueValues] = useState(0);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [showRefferModel, setRefferModel] = useState(false);
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [upcomingWebinars, setUpcomingWebinars] = useState([]);
  const [networkPeopleList, setNetworkPeopleList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleClose = () => {
    setRefferModel(false);
  };

  const takeAction = (item) => {
    if (item.isAdminPromote) {
      Mixpanel.track("Admin portal promote visited", {
        promote_id: item.id,
        promote_title: item.title,
        promote_link: item.buttonLink,
      });
    }

    if (!item.buttonLink) {
      setRefferModel(true);
    } else {
      if (item.newTab) window.open(item.buttonLink, "_blank");
      else navigate(item.buttonLink);
    }
  };

  useEffect(() => {
    refreshgetEventList();
    if (permissions["appointment"]) {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let year = dateObj.getUTCFullYear();
      refreshgetAppointmentList(month, year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callgetEventList.hasFetched() &&
      callgetEventList.hasErrors() === false &&
      callgetEventList.data() &&
      callgetEventList.data().data
    ) {
      setCalendarEvents(callgetEventList.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callgetEventList.isFetching()]);

  useEffect(() => {
    if (
      callgetAppointment.hasFetched() &&
      callgetAppointment.hasErrors() === false
    ) {
      setCalendarAppointments(callgetAppointment.data());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callgetAppointment.isFetching()]);

  useEffect(() => {
    refreshGetUpcomingWebinarsAPI();
    refreshGetMyWebinarsAPI();
    refreshPromoteAPI();

    if (accessibilityHelp === "true") {
      const nextButtons = document.getElementsByClassName("slick-next");
      const prevButtons = document.getElementsByClassName("slick-prev");
      if (nextButtons && nextButtons.length > 0) {
        nextButtons[0].setAttribute("aria-label", "Next Slide");
        if (nextButtons[1])
          nextButtons[1].setAttribute("aria-label", "Next Slide");
      }
      if (prevButtons && prevButtons.length > 0) {
        prevButtons[0].setAttribute("aria-label", "Previous Slide");
        if (prevButtons[1])
          prevButtons[1].setAttribute("aria-label", "Previous Slide");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callGetUpcominWebinarsAPI.hasFetched() &&
      callGetUpcominWebinarsAPI.hasErrors() === false &&
      callGetUpcominWebinarsAPI.data() &&
      callGetUpcominWebinarsAPI.data().data
    ) {
      setUpcomingWebinars(callGetUpcominWebinarsAPI.data().data);
      if (
        callGetUpcominWebinarsAPI.data().data &&
        callGetUpcominWebinarsAPI.data().data.boost_profile &&
        callGetUpcominWebinarsAPI.data().data.boost_profile.registered_webinar
      ) {
        setStatusList(callGetUpcominWebinarsAPI.data().data.boost_profile);

        const response = filter(
          values(callGetUpcominWebinarsAPI.data().data.boost_profile),
          function (data) {
            return data === true;
          }
        ).length;

        settotalTrueValues(response);
        setShowBoostPopup(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetUpcominWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingGetMyWebinarsAPI.hasFetched() &&
      callingGetMyWebinarsAPI.hasErrors() === false &&
      callingGetMyWebinarsAPI.data() &&
      callingGetMyWebinarsAPI.data().data
    ) {
      setMyWebinars(callingGetMyWebinarsAPI.data().data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetMyWebinarsAPI.isFetching()]);

  useEffect(() => {
    if (
      callPromoteAPI.hasFetched() &&
      callPromoteAPI.hasErrors() === false &&
      callPromoteAPI.data() &&
      callPromoteAPI._data.data &&
      callPromoteAPI._data.data.promotions
    ) {
      const { promotions = [] } = callPromoteAPI._data.data;
      const formattedPromotes = promotions.map((promote) => ({
        title: promote.title,
        topImage: promote.file_url,
        buttonText: promote.action_text,
        buttonLink: promote.link,
        subTitle: promote.description,
        className: "w-70",
        isAdminPromote: true,
        id: promote.id,
        newTab: true,
        permission:
          promote.student_audience_type ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.BOTH ||
          (promote.student_audience_type ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL &&
            users?.student_view_preference ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL) ||
          (promote.student_audience_type ===
            STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC &&
            users?.student_view_preference ===
              STUDENT_VIEW_PREFERENCES_OPTIONS.DOMESTIC)
            ? true
            : false,
      }));
      setPromotions([
        ...INTERSTRIDE_PRODUCTS_SLIDER,
        ...formattedPromotes,
        ...HOME_SLIDER,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPromoteAPI.isFetching()]);

  useEffect(() => {
    if (
      jobsCountryList.length === 0 ||
      !jobsCountryListVersion ||
      jobsCountryListVersion !== JOBS_COUNTRY_LIST_VERSION
    ) {
      refreshGetJobsCountriesAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callingGetJobsCountriesAPI.hasFetched() &&
      callingGetJobsCountriesAPI.hasErrors() === false &&
      callingGetJobsCountriesAPI.data() &&
      callingGetJobsCountriesAPI.data().data &&
      callingGetJobsCountriesAPI.data().data.countries
    ) {
      dispatch(
        setJobsCountryList({
          data: callingGetJobsCountriesAPI.data().data.countries,
          version: JOBS_COUNTRY_LIST_VERSION,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetJobsCountriesAPI.isFetching()]);

  useEffect(() => {
    if (nationality && jobsCountryList?.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.label === nationality
      );
      setSelectedCountry(selectedCountry);
      if (permissions["chat"] && selectedCountry?.label)
        refreshGetRecommendedNetWorkPeopleAPI({
          country: selectedCountry?.label,
          page: 1,
          per_page: 5,
        });
      else setNetworkPeopleList([]);
    }
  }, [nationality, jobsCountryList]);

  useEffect(() => {
    if (
      callingGetRecommendedNetWorkPeopleAPI.hasFetched() &&
      callingGetRecommendedNetWorkPeopleAPI.hasErrors() === false
    ) {
      const { recommended_network = [], current_page } =
        callingGetRecommendedNetWorkPeopleAPI.data()?.data;

      const _discoverNetworks =
        current_page === 1
          ? [...recommended_network]
          : [...discoverNetworks, ...recommended_network];

      setNetworkPeopleList(recommended_network);
      setDiscoverNetworks(_discoverNetworks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedNetWorkPeopleAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  let settings = {
    accessibility: true,
    dots: true,
    arrows: accessibilityHelp === "true" ? true : false,
    infinite:
      promotions.filter((promotion) => promotion.permission)?.length > 1
        ? true
        : false,
    speed: 2000,
    pauseOnHover: true,
    autoplay: accessibilityHelp === "true" ? false : true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: accessibilityHelp === "true" ? "accessibility-slider" : "",
    customPaging: function renderButtons(i) {
      return (
        <button
          aria-label={`slide ${i + 1}`}
          id={`${isResponsive ? "responsive-" : ""}btn-slide-${i}`}
          {...(i === currentSlide ? { "aria-current": true } : {})}
        >
          {i + 1}
        </button>
      );
    },
    beforeChange: function (_, next) {
      setNextSlide(next);
    },
    afterChange: function (i) {
      setCurrentSlide(i);
    },
  };

  // const LegalpadPromotes = [
  //   {
  //     isLegalpadPromote: true,
  //     image: "/images/Interstride-Banner-Option.png",
  //     buttonLink: "https://legalpad.io/partner/interstride/",
  //     promoteImageClassname: "us-work-visa__rounded",
  //   },
  // ];

  const INTERSTRIDE_PRODUCTS_SLIDER = [
    {
      customUI: <OPTCalculculatorSlide />,
      permission:
        permissions["opt_calculator"] &&
        users?.student_view_preference ===
          STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    {
      customUI: <ChromeExtensionSlide />,
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    {
      customUI: <MobileAppSlide />,
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
  ];

  const HOME_SLIDER = [
    // ...(permissions["chat"]
    //   ? [
    //       {
    //         title: "Meet advisors, alumni mentors, and more.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa3 },
    //           { name: "Mark Smith", imageSrc: STa2 },
    //           { name: "Mark Smith", imageSrc: STa1 },
    //           { name: "Mark Smith", imageSrc: STa4 },
    //         ],
    //         buttonText: "Network",
    //         buttonLink: routes.NETWORK.DASHBOARD,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["visa_insights"]
    //   ? [
    //       {
    //         title: "Looking for a job in the United States?",
    //         subTitle:
    //           "Get Visa Insights about companies based in the U.S. to understand what opportunities are available.",
    //         buttonText: "Get U.S. Visa Insights",
    //         buttonLink: routes.JOBS.VISA,
    //         className: "",
    //       },
    //     ]
    //   : []),
    // ...(permissions["topics"]
    //   ? [
    //       {
    //         title: "Stay up to date on Topics you care about.",
    //         subImage: [
    //           { name: "Mark Smith", imageSrc: STa5 },
    //           { name: "Mark Smith", imageSrc: STa6 },
    //           { name: "Mark Smith", imageSrc: STa7 },
    //           { name: "Mark Smith", imageSrc: STa8 },
    //         ],
    //         buttonText: "View Topics",
    //         buttonLink: routes.NETWORK.TOPICS,
    //         className: "",
    //       },
    //     ]
    //   : []),
    {
      title: "Discover orientation courses tailored for you.",
      subTitle:
        "Learn more about U.S. work authorization and job search startegies for international students.",
      buttonText: "View Courses",
      buttonLink: `${routes.LEARN.COURSES}?course=orientation`,
      className: "",
      permission:
        users?.student_view_preference ===
        STUDENT_VIEW_PREFERENCES_OPTIONS.INTERNATIONAL,
    },
    // {
    //   title: "Take our immigration course.",
    //   subTitle:
    //     "Get an in-depth understanding of all the work authorization options.",
    //   buttonText: "View Courses",
    //   buttonLink: routes.LEARN.COURSES,
    //   className: "",
    // },
  ];

  return (
    <DashboardRightSidebarStyles>
      <div className="components-wrapper-right-sidebar">
        {callPromoteAPI.isFetching() ? (
          <div className="promote-previews-loading-container">
            <ShimmerTitle />
            <ShimmerText />
            <ShimmerButton size={"lg"} />
          </div>
        ) : promotions.filter((promotion) => promotion.permission)?.length >
          0 ? (
          <React.Fragment>
            <Slider {...settings}>
              {promotions
                .filter((promotion) => promotion.permission)
                .map((item, i) => (
                  <CardCustom
                    className={`slider-card ${
                      accessibilityHelp === "true"
                        ? "accessibility-slider-card"
                        : ""
                    }`}
                    key={i}
                  >
                    {item.customUI ? (
                      item.customUI
                    ) : (
                      <>
                        {item.topImage && (
                          <div className={`top-image ${item.className}`}>
                            <img src={item.topImage} alt="Illustration" />
                          </div>
                        )}
                        {item.title && (
                          <div
                            className={`title text-primary ${item.titleClass}`}
                          >
                            {item.title}
                          </div>
                        )}
                        {item.subTitle && (
                          <div className="sub-title text-primary">
                            {item.subTitle}
                          </div>
                        )}
                        {item.subTitle2 && (
                          <div className="title title-visto text-primary">
                            {item.subTitle2}
                          </div>
                        )}
                        {item.subImage && (
                          <div className={`avatar-list ${item.className}`}>
                            <ul>
                              {item.subImage.map((item, i) => (
                                <li key={i}>
                                  <Avatar src={item.imageSrc} alt="Subimage" />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {item.isMobSquadPromote || item.isLegalpadPromote ? (
                          <div
                            className={`full-image ${
                              item.promoteImageClassname ?? ""
                            }`}
                          >
                            <a
                              href={item.buttonLink}
                              rel="noopener noreferrer"
                              target="_blank"
                              aria-label="promote ads"
                              onClick={(e) => {
                                e.preventDefault();
                                Mixpanel.track("Custom Ads Clicked", {
                                  promote_title: item.isLegalpadPromote
                                    ? "Legalpad"
                                    : "Mobsquad",
                                  page_source: "Dashboard -> Promotes",
                                  promote_link: item.buttonLink,
                                });
                                window.open(item.buttonLink);
                              }}
                            >
                              <img
                                src={item.image}
                                alt={
                                  item.isMobSquadPromote
                                    ? "Mobsquad Ad"
                                    : "Legalpad Ad"
                                }
                              />
                            </a>
                          </div>
                        ) : (
                          <ButtonCustom
                            width={265}
                            onClick={() => takeAction(item)}
                            keepLabelCase={true}
                            isDisabled={nextSlide !== i}
                            className="bg-blue"
                          >
                            {item.buttonText}
                          </ButtonCustom>
                        )}
                      </>
                    )}
                  </CardCustom>
                ))}
            </Slider>
          </React.Fragment>
        ) : (
          <></>
        )}

        <div className="sticky-right-content">
          {permissions["appointment"] && upCommingEvents.length > 0 ? (
            <div className="appointments-wrapper">
              <div className="header">
                <h2 className="title text-primary">Appointments</h2>
                <div className="actions">
                  <Link
                    to={routes.TOOLS.MEET}
                    className="nlink-secondary link-focus"
                  >
                    View all{" "}
                    {accessibilityHelp === "true" ? " appointments" : ""}
                  </Link>
                </div>
              </div>
              <EventList events={upCommingEvents} />
            </div>
          ) : null}
          <div className="mb-20">
            <RecommendedNetworkCard
              networkPeopleList={networkPeopleList}
              selectedCountry={selectedCountry}
            />
          </div>
          <div className="webinars-wrapper">
            {permissions["webinar"] &&
              permissions["pastOrUpcomingWebinarsAvailable"] && (
                <React.Fragment>
                  <div className="header">
                    <h2 className="title text-primary">Upcoming webinars</h2>
                    <div className="actions">
                      <Link
                        to={routes.LEARN.WEBINARS}
                        className="nlink-secondary link-focus"
                      >
                        Go to webinars
                      </Link>
                    </div>
                  </div>

                  {callGetUpcominWebinarsAPI.isFetching() ? (
                    <div className="webinar-previews-loading-container">
                      {Array.from(Array(5).keys()).map((item, index) => (
                        <ShimmerCategoryItem key={index} />
                      ))}
                    </div>
                  ) : upcomingWebinars && upcomingWebinars.length > 0 ? (
                    upcomingWebinars.map((item, i) => {
                      if (i < 2) {
                        return (
                          <div className="webinar-items" key={i}>
                            <EventCard
                              key={i}
                              id={`${
                                isResponsive ? "responsive-" : ""
                              }webinar-${item.id}`}
                              className="event-card cursor-default limit-webinar-content"
                              title={get(item, "name", "")}
                              subTitle={moment
                                .unix(item?.epoch_time)
                                .local()
                                .format("ddd MMMM Do, YYYY")}
                              myWebinars={myWebinars}
                              duration={`${moment
                                .unix(item?.epoch_time)
                                .local()
                                .format(
                                  "hh:mm a"
                                )} - ${calculateLocalEndTimeFromEpoch(
                                item?.epoch_time,
                                item && item.duration && !isNull(item.duration)
                                  ? item.duration.split(" ")[0]
                                  : 0,
                                "minutes"
                              )} (${getLocalTzAbbr()})`}
                              tagName={item.webinar_category_name}
                              actionName="View details"
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <EmptyMyWebinar className="empty-web" />
                  )}
                </React.Fragment>
              )}

            <CustomDialog
              open={showRefferModel}
              title={"Refer a friend"}
              handleClose={handleClose}
              className="friend-model"
            >
              <div className="friend-dialog-content">
                <div className="share-content">
                  Interstride is a one-stop-shop that empowers international
                  students in their job search, networking, mentorship and
                  career exploration. <br />
                  <br /> Help me onboard University Name to Interstride by
                  clicking on{" "}
                  <a
                    href="/#"
                    className="text-secondary link-focus"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    aria-label="onboarding help link"
                  >
                    this link!
                  </a>
                </div>
                <div className="social-links">
                  <ul>
                    <li>
                      <img src={linkedinIcon} alt="linkedin" />
                    </li>
                    <li>
                      <img src={twitterIcon} alt="twitter" />
                    </li>
                    <li>
                      <img src={facebookIcon} alt="facebook" />
                    </li>
                    <li>
                      <img src={facebookIcon} alt="facebook" />
                    </li>
                  </ul>
                  <ButtonCustom
                    color="primary"
                    width={220}
                    className="bg-light-blue ml-auto"
                  >
                    Copy to Clipboard
                  </ButtonCustom>
                </div>
                <div className="btn">
                  <ButtonCustom
                    onClick={() => handleClose()}
                    width="124"
                    height={30}
                  >
                    Got It!
                  </ButtonCustom>
                </div>
              </div>
            </CustomDialog>
          </div>
        </div>
      </div>
      {showBoostPopup && (
        <BoostPopup
          message={
            totalTrueValues === statusList.length
              ? "Your profile boost is complete"
              : "You've just registered for your first webinar."
          }
          sub_message={
            totalTrueValues === statusList.length
              ? "You're ready to gain the most from the Interstride platform."
              : "You're on the way to gaining the most from the Interstride platform."
          }
          totalSteps={statusList.length}
          totalTrueValues={parseInt(totalTrueValues)}
          closeFn={closeFN}
        />
      )}
    </DashboardRightSidebarStyles>
  );
};

export default DashboardRightSidebar;
