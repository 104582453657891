import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

import FindJobStyles from "./FindJobStyles";
import { useAppState } from "../../../context";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import CardCustom from "../../CardCustom/CardCustom";
import DropdownCustom from "../../DropdownCustom/DropdownCustom";
import TextboxCustom from "../../Textbox/TextboxCustom";
import {
  US_VISA_LIST,
  US_VISA_LIST_DOMESTIC_VIEW,
} from "../../../constant/mockdata";

import FilledFilterIcon from "../../../assets/svg/filled-filter-Icon.svg";
import FilterIcon from "../../../assets/svg/filter-Icon.svg";

const FindJob = ({ setSelectedCountry = () => {} }) => {
  const navigate = useNavigate();
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");

  const { setJobsFilter } = useAppState("job");
  const { jobsCountryList = [] } = useSelector((store) => store.jobStore);
  const { users = {} } = useSelector((store) => store.userStore);
  const student_view_preference =
    users?.student_view_preference || "international";

  const [openFilter, toggleFilter] = useState(false);
  const [state, setState] = useState({
    keyword: null,
    country: "us",
    search_type:
      student_view_preference === "international"
        ? "top_1000_employers"
        : US_VISA_LIST_DOMESTIC_VIEW[0].value,
    job_search_type: "approx",
  });
  const [showPopover, setPopover] = useState(null);
  const [showVisaPopover, setVisaPopover] = useState(null);
  const [showKeywordPopover, setKeywordPopover] = useState(null);

  const openVisa = Boolean(showVisaPopover);
  const idVisa = openVisa ? "simple-visa-popover" : undefined;
  const openKeyword = Boolean(showKeywordPopover);
  const idKeyword = openKeyword ? "simple-keyword-popover" : undefined;
  const open = Boolean(showPopover);
  const id = open ? "simple-popover" : undefined;

  const handleQuestionClick = (event, type = "visa") => {
    event.preventDefault();
    type === "visa"
      ? setVisaPopover(event.currentTarget)
      : setKeywordPopover(event.currentTarget);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handleClose = () => {
    setPopover(null);
  };

  const handleQuestionClose = (type = "visa") => {
    type === "visa" ? setVisaPopover(null) : setKeywordPopover(null);
  };

  const isGreenRiverStudent =
    users &&
    (users.school_id === 12198 ||
      users.school_name === "Green River College" ||
      users?.school_user_email?.includes("greenriver.edu"));

  const handleChange = (value, name) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    setJobsFilter({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobsCountryList.length > 0)
      setSelectedCountry(
        jobsCountryList.find((rec) => rec.value === state.country)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.country, setSelectedCountry]);

  const applyCurrentFilters = () => {
    toggleFilter(false);
    setJobsFilter({
      ...state,
    });
    navigate("/jobs/search");
  };

  return (
    <FindJobStyles>
      <div
        className={`find-job-section collapsible-view ${
          openFilter ? "open" : ""
        }`}
      >
        <h2 aria-describedby={id} className="title-primary mb-15">
          Find your next job
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              toggleFilter(!openFilter);
            }}
            className="collapse-icon"
            aria-label="Toggle filter"
          >
            {openFilter ? (
              <img src={FilledFilterIcon} alt="Filter Icon" />
            ) : (
              <img src={FilterIcon} alt="Filter Icon" />
            )}
          </a>
        </h2>

        <Popover
          id={id}
          open={open}
          anchorEl={showPopover}
          onClose={handleClose}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {isGreenRiverStudent ? (
            <Typography>
              IMPORTANT: All off-campus employment requires special
              authorization for international students. Unauthorized employment
              is a violation of F-1 visa status and will result in I-20
              termination. Please talk with your Advisor before pursuing any
              off-campus employment. On-campus employment requires a 2.5
              cumulative GPA. IEP students must finish the IEP program before
              they are eligible for on-campus employment. For more information
              regarding on-campus employment contact Toya Turner,
              tturner@greenriver.edu.
            </Typography>
          ) : (
            <Typography>
              International students on a nonimmigrant F-1 student visa should
              contact International Student and Scholar Services (ISSS) for work
              authorization prior to working off-campus. F-1 students with
              approved OPT or STEM OPT do not need to contact ISSS for work
              authorization.
            </Typography>
          )}
          {accessibilityHelp === "true" && (
            <div className="close-button">
              <ButtonCustom onClick={handleClose}>Got It!</ButtonCustom>
            </div>
          )}
        </Popover>
        <Grid container>
          <Grid item xs={12}>
            <CardCustom className="search-card">
              <div className="form-field-group">
                <label className="form-label textbox-label">
                  Search keyword
                  {/* <QuestionIcon
                    onClick={(e) => handleQuestionClick(e, "keyword")}
                  /> */}
                </label>
                <Popover
                  id={idKeyword}
                  open={openKeyword}
                  anchorEl={showKeywordPopover}
                  onClose={() => handleQuestionClose("keyword")}
                  className="popover-wrapper"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography>
                    Search for job titles and positions related to your major
                    and degree
                  </Typography>
                  {accessibilityHelp === "true" && (
                    <div className="close-button">
                      <ButtonCustom
                        onClick={() => handleQuestionClose("keyword")}
                      >
                        Got It!
                      </ButtonCustom>
                    </div>
                  )}
                </Popover>
                <TextboxCustom
                  name="search-skill"
                  type="text"
                  placeholder="Enter title, skill, or company"
                  label=""
                  lableaignment="left"
                  onChange={(e) => handleChange(e.target.value, "keyword")}
                  onEnterKey={applyCurrentFilters}
                />
              </div>
              <div className="form-field-group">
                <DropdownCustom
                  name="country"
                  label="Select country"
                  placeholder={"Country"}
                  justifyContent="left"
                  options={jobsCountryList}
                  value={jobsCountryList.find(
                    (rec) => rec.value === state.country
                  )}
                  onChange={(e) => handleChange(e.value, "country")}
                />
              </div>
              {/* Enable only for US */}
              {state.country && state.country === "us" && (
                <div className="form-field-group">
                  <label className="textbox-label">
                    Companies
                    {/* <QuestionIcon
                      onClick={(e) => handleQuestionClick(e, "visa")}
                    /> */}
                  </label>
                  <Popover
                    id={idVisa}
                    open={openVisa}
                    anchorEl={showVisaPopover}
                    onClose={() => handleQuestionClose("visa")}
                    className="popover-wrapper"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {student_view_preference === "international" ? (
                      <Typography>
                        <b>Top 500 H-1B:</b> Top 500 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship from the largest H-1B
                        employers. These employers may have global offices to
                        relocate you internationally if H-1B lottery is
                        unsuccessful.
                        <br />
                        <br />
                        <b>Top 1000 H-1B:</b> Top 1000 H-1B sponsoring employers
                        from the past year responsible for hiring early talent.
                        High probability of sponsorship, but includes smaller
                        companies with limited sponsorship history.
                        <br />
                        <br />
                        <b>Sponsored last year:</b> The jobs were sponsored last
                        year. Highest probability of sponsorship.
                        <br />
                        <br />
                        <b>Cap-exempt:</b> Employers exempt from the H-1B
                        lottery process. High probability of sponsorship.
                        <br />
                        <br />
                        <b>All companies:</b> Relevant for domestic students.
                        Low probability of sponsorship for international
                        students.
                      </Typography>
                    ) : (
                      <Typography>
                        <b>Fortune 500:</b> The Fortune 500 is a list of the
                        largest 500 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Fortune 1000:</b> The Fortune 1000 is a list of the
                        largest 1000 companies in the United States ranked by
                        total revenue.
                        <br />
                        <br />
                        <b>Crunchbase Unicorn:</b> The Crunchbase Unicorn Board
                        is a curated list of the most valuable private companies
                        in the world. Powered by Crunchbase’s comprehensive
                        data, this unicorn company list is updated as companies
                        are valued at $1 billion or more in a new funding round.
                      </Typography>
                    )}
                    {accessibilityHelp === "true" && (
                      <div className="close-button">
                        <ButtonCustom
                          onClick={() => handleQuestionClose("visa")}
                        >
                          Got It!
                        </ButtonCustom>
                      </div>
                    )}
                  </Popover>
                  <DropdownCustom
                    name="visa"
                    label=""
                    placeholder="Companies"
                    justifyContent="left"
                    options={
                      student_view_preference === "international"
                        ? US_VISA_LIST
                        : US_VISA_LIST_DOMESTIC_VIEW
                    }
                    value={(student_view_preference === "international"
                      ? US_VISA_LIST
                      : US_VISA_LIST_DOMESTIC_VIEW
                    ).find((rec) => rec.value === state.search_type)}
                    onChange={(e) => handleChange(e.value, "search_type")}
                  />
                </div>
              )}

              <div className="searchBtn">
                <ButtonCustom onClick={applyCurrentFilters} width="250">
                  Search
                </ButtonCustom>
              </div>
            </CardCustom>
          </Grid>
        </Grid>
      </div>
    </FindJobStyles>
  );
};
export default FindJob;
